import {
  PiWhatsappLogoLight,
  PiTelegramLogoLight,
  PiTiktokLogoLight,
  PiYoutubeLogoLight,
  PiInstagramLogoLight,
  PiFacebookLogoLight,
  PiTwitterLogoLight,
  PiLinkedinLogoLight
} from "react-icons/pi";

import {
  placeholder1,
  jalursukses,
  ubc,
  fac1,
  fac2,
  fac3,
  fac4,
  fac5,
  fac6,
  fac7,
  fac8,
  penghargaan1,
  beasiswa1,
  beasiswa2,
  beasiswa3,
  beasiswa4,
  beasiswa5,
  beasiswa6,
  beasiswa7,
  beasiswa8,
  beasiswa9,
  pmb1,
  pmb2,
  pmb3,
  pmb4,
  pmb5,
  pmb6,
  pmb7,
  pmb8,
  pmb9,
  pmb10,
  pmb11,
  logo,
  tuition1,
  tuition2,
  lecturer,
  apoteker,
  s1farmasi,
  manajemen,
  akuntansi,
  hukum,
  sisteminformasi,
  informatika,
  ilmukomunikasi,
  d3farmasi,
  d3kebidanan,
  landingheader1,
  landingheader2,
  landingheader3,
  landingheader4,
  rektor,
  rektorProfile,
  wr1,
  // wr2,
  wr3,
  lembagaPMI,
  lembagaPPM,
  kaprodiAkuntansi,
  kaprodiD3Farmasi,
  kaprodiD3Kebidanan,
  kaprodiIlkom,
  kaprodiIlmuHukum,
  kaprodiInfor,
  kaprodiManajemen,
  kaprodiPSPPA,
  kaprodiS1Farmasi,
  kaprodiSisfor,
  ketuaGugusFBTS,
  ketuaGugusFK,
  kuppmFBTS,
  kuppmFK,
  dekanfbts,
  dekanfk,
  ucsi,
  uistinye,
  umahsa,
  umara,
  umsu,
  usainsmy,
  utmu,
  utoyama,
  uzaim,
  uchulangkon,
  ukhonkaen,
  umahidol,
  uramsit,
  uthonduc,
  aam1,
  aam2,
  biofarma,
  brivit1,
  brivit2,
  bsi,
  combiphar,
  dipa,
  herosupermarket,
  ismut,
  mensa,
  natura,
  penta,
  royal,
  artikel1p1,
  artikel2p1,
  kurikulumHukum,
  kurikulumApotekernew,
  kurikulumFarmasi,
  kurikulumManajemen,
  kurikulumAkuntansi,
  kurikulumInformasi,
  kurikulumInformatika,
  kurikulumKomunikasi,
  kurikulumd3farmasi,
  kurikulumd3kebidanan,
  idFlag,
  usFlag,
  artikel3,
  artikel4,
  artikel5,
  artikel6,
  artikel7,
  artikel8,
  artikel9,
  artikel10,
  artikel11,
  artikel12,
  artikel13,
  artikel14,
  artikel15,
  artikel16,
  artikel17,
} from "../assets";

export const navLinks = [
  {
    i18nKey: "navbar.navlinks.home.title",
    id: "beranda",
    title: "Beranda",
    path: "/",
  },
  {
    i18nKey: "navbar.navlinks.about.title",
    id: "tentang",
    title: "Tentang",
    isActive: false,
    child: [
      {
        i18nKey: "navbar.navlinks.about.child.history",
        id: "sejarah",
        title: "Sejarah",
        path: "/sejarah",
      },
      {
        i18nKey: "navbar.navlinks.about.child.rector",
        id: "sambutan-rektor",
        title: "Sambutan Rektor",
        path: "/sambutan",
      },
      {
        i18nKey: "navbar.navlinks.about.child.profile",
        id: "tentang-univeral",
        title: "Profil Univeral",
        path: "/tentang",
      },
      {
        i18nKey: "navbar.navlinks.about.child.structure",
        id: "tenaga-pengajar",
        title: "Tenaga Pengajar",
        path: "/tenaga-pengajar",
      },
      {
        i18nKey: "navbar.navlinks.about.child.facility",
        id: "fasilitas",
        title: "Fasilitas",
        path: "/fasilitas",
      },
      {
        i18nKey: "navbar.navlinks.about.child.achievement",
        id: "penghargaan",
        title: "Penghargaan",
        path: "/penghargaan",
      },
    ],
  },
  {
    i18nKey: "navbar.navlinks.admission.title",
    id: "admission",
    title: "Admission",
    isActive: false,
    child: [
      {
        i18nKey: "navbar.navlinks.admission.child.newstudent",
        id: "penerimaan-mahasiswa",
        title: "Penerimaan Mahasiswa",
        path: "https://pmb.univeral.ac.id/",
      },
      {
        i18nKey: "navbar.navlinks.admission.child.educost",
        id: "biaya-pendidikan",
        title: "Biaya Pendidikan",
        path: "/biaya-pendidikan",
      },
      {
        i18nKey: "navbar.navlinks.admission.child.scholarship",
        id: "beasiswa",
        title: "Beasiswa",
        path: "/beasiswa",
      },
      // {
      // i18nKey: "navbar.navlinks.admission.child.requirement",
      //   id: "syarat-pendaftaran",
      //   title: "Syarat Pendaftaran",
      //   path: "/syarat-pendaftaran",
      // },
    ],
  },
  {
    i18nKey: "navbar.navlinks.information.title",
    id: "informasi",
    title: "Informasi",
    isActive: false,
    child: [
      {
        i18nKey: "navbar.navlinks.information.child.job",
        id: "lowongan-kerja",
        title: "Lowongan Kerja",
        path: "/lowongan-kerja",
      },
      {
        i18nKey: "navbar.navlinks.information.child.accomodation",
        id: "informasi-akomodasi",
        title: "Informasi Akomodasi",
        path: "/informasi-akomodasi",
      },
      // {
      //   i18nKey: "navbar.navlinks.information.child.event",
      //   id: "kegiatan-dan-acara",
      //   title: "Kegiatan dan Acara",
      //   path: "/kegiatan-acara",
      // },
      {
        i18nKey: "navbar.navlinks.information.child.faq",
        id: "faq",
        title: "FAQ",
        path: "/faq",
      },
      {
        i18nKey: "navbar.navlinks.information.child.article",
        id: "artikel",
        title: "Artikel",
        path: "/artikel",
      },
    ],
  },
  {
    i18nKey: "navbar.navlinks.contact.title",
    id: "kontak",
    title: "Kontak",
    path: "/kontak",
  },
];

export const landingHeaderImgs = [
  {
    img: landingheader1,
    position: "center bottom",
  },
  {
    img: landingheader2,
    position: "center",
  },
  {
    img: landingheader3,
    position: "bottom center",
  },
  {
    img: landingheader4,
    position: "center",
  },
];

export const TabLinks = [
  {
    id: "pimpinan",
    title: "Pimpinan",
    path: "/",
  },
  {
    id: "fk",
    title: "Fakultas Kesehatan",
    path: "/",
  },
  {
    id: "fbts",
    title: "Fakultas Bisnis Teknologi dan Sosial",
    path: "/",
  },
];

export const universityStats = [
  {
    i18nKey: "landing.statistics.subtitle.lecturers",
    title: "Tenaga Pengajar",
    total: "90",
    textColor: "text-primary-40",
  },
  {
    i18nKey: "landing.statistics.subtitle.students",
    title: "Mahasiswa Aktif",
    total: "1300+",
    textColor: "text-info-40",
  },
  {
    i18nKey: "landing.statistics.subtitle.studyPrograms",
    title: "Program Studi",
    total: "10",
    textColor: "text-success-40",
  },
  {
    i18nKey: "landing.statistics.subtitle.alumni",
    title: "Program Studi",
    total: "3800+",
    textColor: "text-amber-600",
  },
];

export const programStudi = [
  {
    id: "apoteker",
    img: apoteker,
    subject: "Profesi Apoteker",
    link: "/program/profesi-apoteker",
    details: {
      desc: `Program Studi Pendidikan Profesi Apoteker merupakan program pendidikan lanjut bagi para sarjana farmasi yang telah menyelesaikan jenjang akademik sebagai upaya meningkatkan kualitas pendidikan di bidang kefarmasian dan mempersiapkan mahasiswa untuk memiliki pekerjaan dengan persyaratan keahlian khusus Program Studi Profesi Apoteker Universitas Almarisah Madani memiliki keunikan dengan ciri khas yang berbeda dari Perguruan Tinggi Lain yang dapat ditinjau dari keunggulan dalam aspek pengembangan keilmuan/keprofesian, keunggulan dalam capaian pembelajaran, dan keunggulan pada kurikulum.`,
      listDeskripsi: [
        {
          subTitle: "Keunggulan dalam aspek pengembangan keilmuan/keprofesian",
          descTitle: [
            "Memiliki keunggulan networking dan kerjasama dengan institusi pendidikan tinggi farmasi dalam negeri maupun luar negeri diantaranya (Sekolah Farmasi ITB, Fakultas Farmasi UNPAD, Fakultas Farmasi UNAIR, Fakultas Farmasi UNHAS, Fakultas Farmasi UGM, Mahsa University Malaysia, Management and science University Malaysia, Taipei Medical University Taiwan, Toyama University serta Utrecht University. Selain itu, juga telah melaksanakan program Benchmark internasional di School of Pharmacy University of Sydney, Department of Pharmacy and Biomedical Sciences La Trobe University, Utrecht University Netherland.",
            `PSPPA memiliki banyak kerjasama dengan Fasilitas Pelayanan Kesehatan pemerintah maupun swasta sehingga memberikan banyak peluang bagi program studi profesi apoteker STIFA Makassar untuk implementasi tri dharma perguruan tinggi diantaranya apotek Kimia Farma, apotek Plus Group, Rumah Sakit UNHAS, RSUD Kota Makassar, RSUP Tajuddin Chalid, RSUD Labuang Baji, RSUD Haji, RSUD Salewangang, RSUD Makkasau, Seluruh Puskesmas dibawah naungan Dinas Kesehatan Kota Makassar, BPOM Makassar, Balai Kesehatan Tradisional Masyarakat Sulawesi Selatan, Badan Narkotika Nasional, Combiphar, Biofarma, Dipa Pharmalab, Nufarindo, LAFI AD, LAFI AL, Farmapol, Air Mancur, Pertiwi Agung, Natura Alam Indo, Anugrah Argon Medica, Mensa Bina Sukses, Pentavalent, Kimi Farma Trade and Distribution, Trisapta Jaya, Rajawali Nusindo, dll.`,
            `Kualifikasi dosen berpendidikan S3 dan S2 serta memiliki banyak jumlah preseptor yang kompeten yang akan mengampu mata kuliah praktik kerja di wahana praktik sehingga mendukung dalam pemenuhan target ketercapaian Standar Kompetensi Apoteker Indonesia.`,
            `Memiliki karakter dalam bidang keilmuan pelayanan kefarmasian dan bahan alam yang in line dengan visi misi institusi dan program studi sarjana farmasi dengan profil lulusan MADANI (MAnager, Decision-mAker, Negosiator, Integrity)`,
          ],
        },
        {
          subTitle: `Keunggulan dalam Kajian Capaian Pembelajaran`,
          descTitle: [
            "Terdapat 29 Capaian Pembelajaran Lulusan (CPL) PSPA Universitas Almarisah Madani yang dikembangkan berdasarkan profil lulusan yang meliputi unsur sikap, keterampilan umum, keterampilan khusus, dan pengetahuan yang mengacu pada Permendikbud Nomor 3 Tahun 2020 tentang Standar Nasional Pendidikan Tinggi dan Standar Kompetensi Apoteker Indonesia 2023. Keseluruhan CPL mendasari kompetensi apoteker bidang pelayanan, bidang distribusi, dan bidang produksi. Kurikulum Program Profesi Apoteker terdiri atas 31 SKS meliputi 17 SKS Mata Kuliah (semester 1) dan 14 SKS Mata Kuliah (semester dua). Mata kuliah semester satu meliputi: Praktek Kerja Profesi Apoteker (PKPA) Rumah Sakit (8 SKS), PKPA Apotek (6 SKS), PKPA Puskesmas (4 SKS). Mata kuliah semester dua meliputi PKPA Industri Farmasi (6 SKS), PKPA Distribusi farmasi (4 SKS), Uji Kompetensi Mahasiswa Program Profesi Apoteker Indonesia (UKMPPAI) (2 SKS) dan Ujian Komprehensif (1 SKS). Studi Kasus Pelayanan Kefarmasian, Industri Farmasi, Distribusi, dan pemerintahan terintegrasi dalam seluruh mata kuliah PKPA yang diselenggarakan dengan metode CBL (Case Based Learning) dan diperdalam dengan menghadirkan pakar melalui kuliah tamu, Secara keseluruhan proses praktek yang diprogram adalah sebesar 100%",
          ],
        },
        {
          subTitle: `Prestasi Program Studi Pendidikan Profesi Apoteker (PSPPA)`,
          descTitle: [
            `PSPPA STIFA Makassar sebagai Institusi dengan pencapaian persentase kelulusan tertinggi (77,36%) di Indonesia Timur berdasarkan Surat Keputusan Komite Nasional Uji Kompetensi Mahasiswa Bidang Kesehatan Kementerian Pendidikan, Kebudayaan, Riset, dan Teknologi Nomor. 0970/KOM-Kes/VIII/2023 tanggal 13 Agustus 2023.`,
            `PSPPA STIFA Makassar sebagai Program Studi Profesi Apoteker yang baru dengan pencapaian persentase kelulusan tertinggi diantara PSPA baru yang lainnya berdasarkan Berita Acara Panitia Nasional Uji Kompetensi Apoteker Indonesia BA.01/PAN-UKAI/II/2023 tentang Penetapan Kelulusan Peserta Uji Kompetensi Mahasiswa Program Profesi Apoteker Indonesia Periode Februari 2023 dengan persentase tingkat kelulusan sebesar 65%.`,
          ],
        },
      ],
      career: `Peluang Kerja :`,
      listKarir: [
        `Lembaga Pemerintahan seperti BPOM, Kemenkes, Dinkes, Puskesmas`,
        `Industri Obat, Obat Tradisional, Obat Veteriner, Kosmetika, dan Makanan Minuman`,
        `Rumah Sakit Pemerintah dan Swasta`,
        `Pedagang Besar Farmasi (PBF)`,
        `Apotek`,
        `Laboratorium Klinik`,
        `Perguruan Tinggi Negeri maupun Swasta`,
        `Peneliti`,
        `Entrepeneur Farmasi`,
        `TNI/POLRI`,
      ],
      kurikulum: kurikulumApotekernew,
    },
  },
  {
    id: "s1farmasi",
    img: s1farmasi,
    subject: "Farmasi",
    link: "/program/farmasi",
    details: {
      desc: `Program Studi Sarjana Farmasi hadir guna menyelenggarakan proses pembelajaran secara komprehensif yang dapat diterapkan untuk membuat obat dari bahan alam, kosmetik, maupun obat sintetik sesuai dengan peraturan. Profesi ini memiliki keterkaitan pengetahuan yang menjadi satu bidang kompetensi unggul meliputi pengetahuan farmakologi dan farmasi klinik, Biologi Farmasi, Farmasetika dan Teknologi Farmasi, serta Analisis Farmasi dan Kimia Medisinal yang penting untuk berkontribusi dalam penemuan obat, pengembangan obat, pengujian obat dari bahan alam dan sintetik serta berfokus pada pengaplikasiannya terhadap peningkatan derajat kesehatan manusia secara aman dan efektif. Dengan kata lain, seseorang yang berprofesi dalam bidang farmasi adalah seorang pakar obat yang menguasai ilmu dan pengetahuan serta teknologi tentang obat secara mendalam dari segala aspeknya, mengusai konsep teoritis bidang pengetahuan kefarmasian secara umum dan konsep teoritis bagian khusus dalam bidang kefarmasian secara mendalam, serta mampu memformulasikan penyelesaian masalah secara prosedural dan dasar-dasar ilmu pengetahuan dan teknologi bidang kefarmasian, yang selanjutnya akan dikembangkan pada program pendidikan strata yang lebih tinggi.`,
      keunggulan: `Keunggulan Program Studi S1 Farmasi-Universitas Almarisah Madani Yaitu Lulusan Dibekali Dengan Kompetensi Keilmuan Secara Komprehensif, Berbagai kegiatan Kemahasiswaan Soft Skill dan Kewirausahaan, Pengajar Profesional, Fasilitas Pembelajaran Modern dan Masa Tunggu Bekerja Setelah Lulus Kurang Dari 6 Bulan.`,
      listDeskripsi: [
        {
          subTitle: `Keunggulan Prodi`,
          descTitle: [
            `Kurikulum S1 Farmasi terintegrasi Ke kurikulum Profesi Apoteker, dan Magister Farmasi`,
            `Peningkatan wawasan keilmuan melalui penyelenggaraan kuliah tamu Perguruan Tinggi dalam dan Luar Negeri serta praktisi dunia kerja`,
            `Dosen dengan lulusan dalam dan luar negeri bergelar magister dan doktor telah tersertifikasi`,
            `Metode pembelajaran yang modern terintegrasi melalui LMS (Learning Management System)`,
            `Pertukaran pelajar  `,
            `Joint Research Collaboration dari Pemerintah dalam dan luar negeri`,
          ],
        },
        {
          subTitle: `Fasilitas Pembelajaran`,
          descTitle: [
            `Laboratorium Farmakologi dan Farmasi Klinik`,
            `Laboratorium Biologi Farmasi`,
            `Laboratorium Analisis Farmasi dan Kimia Medisinal`,
            `Laboratorium Farmasetika dan Teknologi Farmasi`,
            `Laboratorium Penelitian Terpadu`,
            `Laboratorium Mikrobiologi Farmasi`,
            `Laboratorium Teknologi Sediaan Steril`,
            `Laboratorium CBT dan OSCE`,
            `Apotek dan Klinik Kesehatan`,
            `Inkubator Bisnis`,
            `Industri Usaha Kecil Obat Tradisional atau UKOT`,
          ],
        },
      ],
      career: `Peluang Karir :`,
      listKarir: [
        `Peduli & Santun dalam Melayani (Care-Giver)`,
        `Pembuat Keputusan (Decision-Maker)`,
        `Komunikator (Communicator)`,
        `Manager (Manager)`,
        `Pembelajar Sepanjang Hayat (Life-Long Learner)`,
        `Pengajar (Teacher)`,
        `Pemimpin (Leader)`,
        `Peneliti (Researcher)`,
        `Pengusaha (Enterpreuner)`,
      ],
      kurikulum: kurikulumFarmasi,
    },
  },
  {
    id: "manajemen",
    img: manajemen,
    subject: "Manajemen",
    link: "/program/manajemen",
    details: {
      desc: `Dirancang dengan memberikan bekal kepada lulusan program studi manajemen untuk menguasai kompetensi utama di bidang manajemen yaitu Keuangan, SDM, Pemasaran, Operasional, Strategi dan Kewirausahaan. Dengan mengembangkan Proses Bisnis (Business Process Improvement), Penciptaan Nilai Pelanggan (Customer Value Creation), Pengelolaan Keuangan Berbasis Nilai (Value-Based Finance) dan Memimpin Perubahan (Leading Change). Adapun beberapa bidang yang kami tawarkan di prodi manajemen sebagai berikut:
      `,
      listDeskripsi: [
        {
          subTitle: `Manajemen Sumber Daya Manusia`,
          descTitle: [],
        },
        {
          subTitle: `Manajemen Keuangan`,
          descTitle: [],
        },
        {
          subTitle: `Manajemen`,
          descTitle: [],
        },
      ],
      career: `Lulusan program studi Manajemen memiliki peluang bekerja di perusahan bisnis nasional dan multinasional, perusahaan swasta maupun BUMN berbagai sektor (seperti perbankan, jasa, manufaktur), start-up digital/non digital, instansi pemerintah berbagai kementerian, kepala daerah berbagai tingkatan dan posisi lainnya yang menuntut kemampuan manajerial dan kepemimpinan. Lulusan Program Studi Manajemen juga dibekali kemampuan berwirausaha maupun menjadi konsultan bisnis.
      `,
      listKarir: [],
      kurikulum: kurikulumManajemen,
    },
  },
  {
    id: "akuntansi",
    img: akuntansi,
    subject: "Akuntansi",
    link: "/program/akuntansi",
    details: {
      desc: `Program Studi Sarjana Akuntansi  merupakan program studi  rumpun ekonomi dan bisnis yang hadir guna menyelenggarakan proses pembelajaran dan dibina oleh Universitas Almarisah Madani sebagai instituisi Pendidikan guna memenuhi kebutuhan masyarakat dalam menghadapi era distrupsi digital. `,
      listDeskripsi: [
        {
          subTitle: `Program studi akuntansi merupakan program studi unggulan yang hadir dan dapat beradaptasi di tengah persainagn dalam dunia usaha dan dunia industri (DUDI). `,
          descTitle: [
            ` Mampu beradaptasi dalam perkembangan zaman teknologi dan informasi program studi ini memiliki keunggulan dari program studi sejenis dalam menjalankan tri dharma perguruan tingi  dan langsung dapat diimplementasikan dari program pembelajaran yang telah diperoleh dan memiliki tanggung jawab moril dalam pekerjaan dengan professional serta ahli dalam bidangnya . 
          `,
          ],
        },
      ],
      career: `Karir pada bidang akuntansi:`,
      listKarir: [
        `Karir`,
        `Penyaji Informasi Akuntansi`,
        `Pemeriksa Informasi Akuntansi`,
        `Pelaksana Perpajakan`,
        `Akuntan Publik/Swasta`,
        `Akuntan Profesi`,
        `Akuntan Pendidik`,
        `Konsultan`,
        `Wirausaha`,
      ],
      kurikulum: kurikulumAkuntansi,
    },
  },
  {
    id: "hukum",
    img: hukum,
    subject: "Hukum",
    link: "/program/hukum",
    details: {
      desc: `Prodi Ilmu Hukum merupakan salah satu prodi yang berada di bawah naungan Universitas Almarisah Madani. Prodi Ilmu Hukum Bertujuan Menghasilkan lulusan di bidang hukum yang profesional, berintegritas tinggi, peduli pada kepentingan masyarakat luas, dan berorientasi global, serta responsif terhadap kemajuan IPTEK di era digital.`,
      listDeskripsi: [
        {
          subTitle: `Penegak Hukum`,
          descTitle: [
            `Lulusan memiliki kemampuan untuk menyelesaikan permasalahan hukum sesuai dengan lingkup pekerjaan dan profesinya menurut prinsip-prinsip hukum yang berkeadilan dengan kemampuan memanfaatkan teknologi informasi. Serta mampu menjalani tanggung jawab sebagai penegak hukum sesuai dengan etika profesinya.`,
          ],
        },
        {
          subTitle: `Konsultan Hukum`,
          descTitle: [
            `Lulusan memiliki kemampuan untuk menjalankan kegiatan usaha dibidang hukum dengan kemampuan memanfaatkan teknologi informasi yang menghasilkan keuntungan dan menanggung resiko yang akan terjadi dalam kegiatan usahanya.`,
          ],
        },
        {
          subTitle: `Perancang Perundang-undangan dan Kontrak`,
          descTitle: [
            `Lulusan memiliki kemampuan untuk merancang naskah hukum / kontrak atau MoU dengan memanfaatkan teknologi informasi.
          `,
          ],
        },
      ],
      career: `Karir: `,
      listKarir: [
        `Penegak Hukum	: Advokat, Panitera, Jaksa, dan Hakim.`,
        `Tim Legal	: Konsultan Hukum, Perancang Perundang-Undangan, Perancang Kontrak, Diplomat, Mediator, dan Notaris
      `,
      ],
      kurikulum: kurikulumHukum,
    },
  },
  {
    id: "sisfor",
    img: sisteminformasi,
    subject: "Sistem Informasi",
    link: "/program/sistem-informasi",
    details: {
      desc: `Dalam rangka menyiapkan mahasiswa menghadapi perubahan sosial, budaya, dunia kerja dan kemajuan teknologi yang pesat, kompetensi mahasiswa harus disiapkan untuk lebih gayut dengan kebutuhan zaman. Link and match tidak saja dengan dunia industri dan dunia kerja tetapi juga dengan masa depan yang berubah dengan cepat. Universitas Almarisah Madani dituntut untuk dapat merancang dan melaksanakan proses pembelajaran yang inovatif agar mahasiswa dapat meraih capaian pembelajaran yang mencakup aspek sikap, pengetahuan, dan keterampilan secara optimal dan selalu relevan.
      Profil lulusan dilengkapi dengan uraian ringkas kompetensi seluruh profil yang sesuai dengan program pendidikan sarjana, dan keterkaitan profil tersebut dengan keunggulan atau keunikan program studi.
      `,
      listDeskripsi: [
        {
          subTitle: `Programmer`,
          descTitle: [],
        },
        {
          subTitle: `System Analyst`,
          descTitle: [],
        },
        {
          subTitle: `Database Administrator`,
          descTitle: [],
        },
        {
          subTitle: `Web Developer`,
          descTitle: [],
        },
      ],
      career: `Bentuk kegiatan pembelajaran Merdeka Belajar-Kampus Merdeka di Universitas Almarisah Madani, sesuai dengan Permendikbud No 3 Tahun 2020 Pasal 15 ayat 1, dapat dilakukan di dalam Program Studi dan di luar Program Studi meliputi 8 jenis  kegiatan seperti :
      `,
      listKarir: [
        `Pengabdian kepada Masyarakat`,
        `Proyek Kemanusiaan`,
        `Asistensi Mengajar di Satuan Pendidikan`,
        `Penelitian/Riset`,
        `Magang/Praktik Kerja`,
        `Kegiatan Wirausaha`,
        `Pertukaran Mahasiswa`,
        `Studi/Proyek`,
      ],
      kurikulum: kurikulumInformasi,
    },
  },
  {
    id: "infor",
    img: informatika,
    subject: "Informatika",
    link: "/program/informatika",
    details: {
      desc: `Program Studi Teknik Informatika adalah program pendidikan tinggi yang bertujuan untuk mempersiapkan mahasiswa dalam memahami, menerapkan, dan mengembangkan teknologi informasi dan komputer.`,
      listDeskripsi: [
        {
          subTitle: `Pengetahuan Komputasi`,
          descTitle: [],
        },
        {
          subTitle: `Pengembangan Perangkat Lunak`,
          descTitle: [],
        },
        {
          subTitle: `Jaringan Komputer`,
          descTitle: [],
        },
        {
          subTitle: `Basis Data`,
          descTitle: [],
        },
        {
          subTitle: `Kecerdasan Buatan`,
          descTitle: [],
        },
        {
          subTitle: `Keamanan Informasi`,
          descTitle: [],
        },
        {
          subTitle: `Analisis Data`,
          descTitle: [],
        },
      ],
      career: `Program Studi Teknik Informatika memberikan landasan yang kuat dalam bidang teknologi informasi, komputasi, dan pengembangan perangkat lunak. Lulusan program ini memiliki peluang karir yang luas di berbagai sektor, termasuk pengembangan perangkat lunak, analisis data, keamanan informasi, konsultasi teknologi, dan wirausaha teknologi.
      `,
      listKarir: [
        `Pengembang Perangkat Lunak.`,
        `Arsitek Perangkat Lunak`,
        `Administrator Jaringan`,
        `Analis Keamanan Informasi`,
        `Data Scientist`,
        `Konsultan Teknologi`,
        `Pengembang Aplikasi Mobile`,
        `Peneliti Teknologi`,
        `Wirausaha Teknologi`,
      ],
      kurikulum: kurikulumInformatika,
    },
  },
  {
    id: "ilkom",
    img: ilmukomunikasi,
    subject: "Ilmu Komunikasi",
    link: "/program/ilmu-komunikasi",
    details: {
      desc: `Dirancang untuk menghasilkan lulusan yang terampil dalam komunikasi tertulis dan lisan, ditambah menguasai kompetensi di bidang komunikasi Kesehatan berbasis teknologi informasi sehingga dapat diterapkan di bidang-bidang  seperti media, hubungan masyarakat, hingga bisnis.
      `,
      listDeskripsi: [
        {
          subTitle: `Bidang Media`,
          descTitle: [],
        },
        {
          subTitle: `Fotografer, Videografer , editor, penyiar, reporter dan wartawan`,
          descTitle: [],
        },
        {
          subTitle: `Bidang hubungan masyarakat`,
          descTitle: [],
        },
        {
          subTitle: `Hubungan masyarakat, negosiator, master of ceremony, konsultan Kesehatan, spesialis komunikasi Kesehatan`,
          descTitle: [],
        },
        {
          subTitle: `Strategi promosi, manajemen Kesehatan, konten creator, digital marketing`,
          descTitle: [],
        },
      ],
      career: `Fasilitas yang diberikan untuk belajar dalam konsentrasi ini meliputi ruang akademik khusus, yaitu: Studio fotografi, studio radio, studio TV, Laboratorium Publik Speaking, Laboratorium Komputer, Laboratorium Branding & Communication Strategy.`,
      listKarir: [],
      kurikulum: kurikulumKomunikasi,
    },
  },
  {
    id: "d3farmasi",
    img: d3farmasi,
    subject: "D3 Farmasi",
    link: "/program/d3-farmasi",
    details: {
      desc: `Program Pendidikan vokasi kefarmasian jenjang Diploma Tiga (DIII) Farmasi merupakan bagian integral dari program pengembangan tenaga kesehatan untuk menghasilkan tenaga Ahli Madya Farmasi yang terampil dan profesional dalam bidang pelayanan kefarmasian, pengelolaan sediaan farmasi, alat kesehatan (Alkes), dan bahan medis habis pakai (BMHP), serta produksi sediaan farmasi.`,
      listDeskripsi: [
        {
          subTitle: `Sumber Daya Manusia`,
          descTitle: [
            `Dalam pengembangan dan pengelolaan PS, diploma tiga didukung dengan jumlah SDM yang memadai. Dosen sebanyak 7 orang berkualifikasi magister (5 orang) dan Doktoral (2 orang), yang sebagian besar memiliki jabatan akademik lektor.`,
          ],
        },
        {
          subTitle: `Sarana dan Prasarana`,
          descTitle: [
            `Prasarana yang dimiliki meliputi, gedung perkuliahan (kelas), aula, ruang laboratorium (7 laboratorium), ruang rapat, kantor (BAAK/BAUK), ruang dosen, ruang ketua Yayasan, ruang pimpinan (Ketua STIFA dan wakilnya), ruang ketua PS, ruang LPMI, ruang LPPM, ruang CBT, ruang OSCE, ruang perpustakaan, kantin, musala, toilet, ruang dokumen, dapur, ruang klinik, apotek STIFA, area parkir, green house, ruang kandang hewan, pos security, dan lapangan olah raga.`,
          ],
        },
        {
          subTitle: `Kurikulum`,
          descTitle: [
            `Kurikulum Diploma Tiga Farmasi disusun berbasis pada standar kompetensi lulusan, terintegrasi, berdasarkan standar kompetensi yang ditetapkan oleh APDFI dan profil lulusan yang dirancang dari hasil tracer study dan input stakeholder. Kurikulum Diploma Tiga selalu up to date dengan melakukan peninjauan kurikulum secara berkala atau insidentil bila terdapat perubahan peraturan. Untuk penguatan konten kurikulum berbasis pelayanan dikembangkan dengan menambah mata kuliah seperti pengetahuan di bidang swamedikasi, nutrisi parenteral, dan perapotekan. Selain itu untuk mengaplikasikan keilmuan berbasis pelayanan, telah didirikan apotek dan klinik dengan dukungan, bimbingan, dan arahan dari Dokter dan Apoteker, sehingga mahasiswa dapat belajar secara langsung teori sekaligus praktiknya.`,
          ],
        },
        {
          subTitle: `Pendidikan`,
          descTitle: [
            `Dalam aspek Pendidikan kinerja UPPS dan PS dapat dilihat dari ketercapaian capaian pembelajaran yang diantaranya dipantau melalui angka kelulusan yang cukup tinggi selam 3 tahun terakhir yaitu > 85%, IPK > 3,0, persentase ketepatan masa studi > 50%, tingkat kepuasan pengguna lulusan dengan penilaian baik, dan rerata masa tunggu kerja yang singkat yaitu ≤ 3 bulan. Selain itu keberhasilan pendidikan juga ditinjau dari banyaknya perolehan prestasi dari mahasiswa baik di tingkat wilayah/provinsi, nasional maupun internasional.`,
          ],
        },
        {
          subTitle: `Kerja sama`,
          descTitle: [
            `Implementasi kepemimpinan publik juga diterapkan dengan menjalin kerja sama dengan sejumlah 46 mitra dari berbagai instansi (41 instansi dalam negeri dan 5 instansi luar negeri) meliputi RS, apotek, PBF, industri (farmasi, obat tradisonal, dan kosmetik), instansi pemerintah, perguruan tinggi dalam dan luar negeri, serta lembaga lainnya.`,
          ],
        },
        {
          subTitle: `Prestasi Mahasiswa`,
          descTitle: [
            `Data prestasi menunjukkan perolehan sebanyak 30 prestasi dari kegiatan akademik maupun non akademik yang terdiri dari 9 prestasi tingkat wilayah/provinsi, 18 prestasi tingkat nasional, dan 3 prestasi tingkat internasional. Kinerja dalam bidang penelitian dan pengabdian masyarakat yang dilakukan dosen STIFA Makassar juga menunjukkan keunggulan yang baik.`,
          ],
        },
      ],
      career: `Lulusan D3 Farmasi dapat berkarier menjadi :`,
      listKarir: [
        `Pelaksana pelayanan kefarmasian`,
        `Pelaksana Pengelolaan`,
        `Sediaan Farmasi, Alat Kesehatan (Alkes), dan Bahan Medis Habis Pakai (BMHP)`,
        `Pelaksana Produksi Sediaan Farmasi`,
      ],
      kurikulum: kurikulumd3farmasi,
    },
  },
  {
    id: "d3kebidanan",
    img: d3kebidanan,
    subject: "D3 Kebidanan",
    link: "/program/d3-kebidanan",
    details: {
      desc: `Kebidanan D3 dalam proses belajar mengajar bertujuan untuk menghasilkan lulusan yang berperan sebagai Provider Care (pemberi asuhan) Kebidanan yang berakhlak mulia, kompetitif, unggul dalam pelayanan kesehatan balita dan anak pra sekolah.
      `,
      listDeskripsi: [
        {
          subTitle: "Keunggulan Prodi",
          descTitle: [
            `Akademi Kebidanan Universitas Almarisah Madani  dalam belajar mengajar bertujuan untuk menghasilkan lulusan yang berperan sebagai Provider Care (pemberi asuhan) kebidanan yang berakhlak mulia, kompetitif, unggul dalam pelayanan kesehatan balita dan anak pra sekolah), Lulusan menjadi Bidan yang bekerja di sektor pemerintahan, swasta maupun luar negeri juga dapat membuka usaha Praktik Mandiri Bidan, usaha Mom’s treatment,Baby Message & SPA atau meditasi persalinan.`,
          ],
        },
        {
          subTitle: `Visi`,
          descTitle: [
            `Terwujudnya pendidikan tinggi Vokasi yang menghasilkan lulusan Bidan yang berakhlak mulia, kompetitif dalam pelayanan kebidanan sesuai kewenangan, unggul dalam pelayanan kesehatan balita dan anak prasekolah dan  tingkat nasional tahun 2025. `,
          ],
        },
        {
          subTitle: `Misi`,
          descTitle: [
            `Menyelenggarakan dan mengembangkan pendidikan tinggi Vokasi untuk menghasilkan bidan yang kompeten dalam pelayanan kebidanan  dan unggul dalam pelayanan kesehatan balita dan anak prasekolah.`,
            `Mengembangkan penelitian terapan kebidanan yang mengacu pada pelayanan kebidanan, pelayanan kesehatan balita dan anak prasekolah`,
            `Menyelenggarakan pengabdian masyarakat dalam bidang pelayanan kebidanan berkelanjutan serta pendampingan pada keluarga melalui pelayanan kesehatan balita dan anak prasekolah.`,
            `Menyelenggarakan tata kelola program studi secara mandiri,  efektif efisien melalui iklim belajar yang nyaman, harmonis, saling bekerjasama dan saling menghargai antar svitas akademika`,
            ` Menjalin kerjasama dengan berbagai instansi kesehatan baik negeri maupun swasta serta mitra kerja terkait lainnya sebagai bagian dari pengembangan Prodi D III Kebidanan Universitas Almarisah Madani.`,
          ],
        },
        {
          subTitle: `Tujuan`,
          descTitle: [
            `Tujuan pendidikan Diploma III Kebidanan Universitas Almarisah Madani adalah untuk menghasilkan Ahli Madya Kebidanan sebagai tenaga bidan pelaksana yang mampu melaksanakan tugas sesuai kewenangan dan kompetensinya.`,
          ],
        },
        {
          subTitle: `Sasaran`,
          descTitle: [
            `Menyelenggarakan dan mengembangkan pendidikan tinggi Vokasi untuk menghasilkan bidan yang kompeten dalam pelayanan kebidanan  dan unggul dalam pelayanan kesehatan balita dan anak prasekolah.`,
            `Mengembangkan penelitian terapan kebidanan yang mengacu pada pelayanan kebidanan, pelayanan kesehatan balita dan anak prasekolah`,
            `Menyelenggarakan pengabdian masyarakat dalam bidang pelayanan kebidanan berkelanjutan serta pendampingan pada keluarga melalui pelayanan kesehatan balita dan anak prasekolah.`,
            `Menyelenggarakan tata kelola program studi secara mandiri,  efektif efisien melalui iklim belajar yang nyaman, harmonis, saling bekerjasama dan saling menghargai antar svitas akademika`,
            `Menjalin kerjasama dengan berbagai instansi kesehatan baik negeri maupun swasta serta mitra kerja terkait lainnya sebagai bagian dari pengembangan Prodi D III Kebidanan Almarisah Madani`,
          ],
        },
      ],
      career: ``,
      listKarir: [],
      kurikulum: kurikulumd3kebidanan,
    },
  },
];

export const jalurSukses = [
  {
    image: jalursukses,
    title: "Start-up Business",
  },
  {
    image: jalursukses,
    title: "Start-up Business",
  },
  {
    image: jalursukses,
    title: "Start-up Business",
  },
];

export const partners = {
  universities: [
    ucsi,
    uistinye,
    umahsa,
    umara,
    umsu,
    usainsmy,
    utmu,
    utoyama,
    uzaim,
    uchulangkon,
    ukhonkaen,
    umahidol,
    uramsit,
    uthonduc,
  ],
  insitutions: [
    aam1,
    aam2,
    biofarma,
    brivit1,
    brivit2,
    bsi,
    combiphar,
    dipa,
    herosupermarket,
    ismut,
    mensa,
    natura,
    penta,
    royal,
  ],
};

export const socialMedias = [
  {
    name: "facebook",
    image: PiFacebookLogoLight,
    link: "https://web.facebook.com/univeral.official",
  },
  {
    name: "twitter",
    image: PiTwitterLogoLight,
    link: "https://www.twitter.com",
  },
  {
    name: "instagram",
    image: PiInstagramLogoLight,
    link: "https://www.instagram.com/univeral.official/",
  },
  {
    name: "youtube",
    image: PiYoutubeLogoLight,
    link: "https://www.youtube.com/",
  },
  {
    name: "tiktok",
    image: PiTiktokLogoLight,
    link: "https://www.tiktok.com/@univeral.official",
  },
  {
    name: "whatsapp",
    image: PiWhatsappLogoLight,
    link: "https://api.whatsapp.com/send?phone=+62882019436805&text=Saya%20tertarik%20untuk%20mengetahui%20lebih%20banyak%20mengenai%20Univeral!",
  },
  // {
  //   name: "telegram",
  //   image: PiTelegramLogoLight,
  //   link: "https://web.telegram.org/",
  // },
  {
    name: "linkedin",
    image: PiLinkedinLogoLight,
    link: "https://linkedin.com/company/universitas-almarisah-madani",
  },
];

export const footerLinks = [
  {
    i18nKey: "footer.links.faq",
    name: "FAQ",
    link: "/faq",
  },
  {
    i18nKey: "footer.links.facility",
    name: "Fasilitas",
    link: "/fasilitas",
  },
  {
    i18nKey: "footer.links.accomodation",
    name: "Akomodasi",
    link: "/informasi-akomodasi",
  },
  {
    i18nKey: "footer.links.article",
    name: "Artikel",
    link: "/artikel",
  },
  // {
  //   i18nKey: "footer.links.event",
  //   name: "Kegiatan & Acara",
  //   link: "/kegiatan-acara",
  // },
];

// article card
export const articles = [
  {
    img: artikel1p1,
    titleId: "Penyerahan Surat Keputusan perubahan bentuk Sekolah Tinggi Ilmu Farmasi (STIFA) Makassar menjadi Universitas Almarisah Madani (Univeral) Makassar dan pelantikan Rektor Universitas Almarisah Madani periode 2023 - 2028",
    titleEn: "Penyerahan Surat Keputusan perubahan bentuk Sekolah Tinggi Ilmu Farmasi (STIFA) Makassar menjadi Universitas Almarisah Madani (Univeral) Makassar dan pelantikan Rektor Universitas Almarisah Madani periode 2023 - 2028",
    link: "/artikel/penyerahan-sk-perubahan-bentuk-sekolah-tinggi-ilmu-farmasi-stifa-makassar-menjadi-universitas-almari",
    details: {
      author: "Admin UNIVERAL",
      date: "18 September 2023",
      textId: [
        `Sekolah Tinggi Ilmu Farmasi Makassar resmi berubah bentuk menjadi Universitas Almarisah Madani pada tanggal 28 Agustus 2023 ditandai dengan keluarnya Surat Keputusan Menteri Pendidikan, Kebudayaan, Riset, dan Teknologi Republik Indonesia tentang Izin penggabungan Sekolah Tinggi Ilmu Farmasi Makassar di Kota Makassar dan Akademi Kebidanan Minasa Upa di Kota Makassar menjadi Universitas Almarisah Madani di Kota Makassar Provinsi Sulawesi Selatan yang diselenggarakan oleh Yayasan Almarisah Madani. `,
        `Surat keputusan Perubahan Bentuk diserahkan langsung oleh Ketua LLDIKTI Wilayah IX, Drs.Andi Lukman kepada Ketua Yayasan Almarisah Madani, Drs. H. Sahibuddin A. Gani, apt. selaku Badan Penyelenggara pada tanggal 08 September 2023. Pada tanggal yang sama, Rektor pertama Universitas Almarisah Madani, Dr. Nursamsiar, M.Si dilantik untuk periode 2023-2028.
        `,
        `Dalam pidatonya, Rektor Universitas Almarisah Madani menyebutkan bahwa Universitas Almarisah  Madani  akan memberikan pendidikan yang relevan, inovatif, dan berorientasi pada nilai, serta memfasilitasi penelitian yang mendorong perkembangan ilmu pengetahuan dan masyarakat. Kami ingin berkolaborasi dengan komunitas lokal dan global untuk mengatasi tantangan-tantangan global dan mewujudkan visi kami bersama. 
        `,
      ],
      textEn: [
        `Sekolah Tinggi Ilmu Farmasi Makassar resmi berubah bentuk menjadi Universitas Almarisah Madani pada tanggal 28 Agustus 2023 ditandai dengan keluarnya Surat Keputusan Menteri Pendidikan, Kebudayaan, Riset, dan Teknologi Republik Indonesia tentang Izin penggabungan Sekolah Tinggi Ilmu Farmasi Makassar di Kota Makassar dan Akademi Kebidanan Minasa Upa di Kota Makassar menjadi Universitas Almarisah Madani di Kota Makassar Provinsi Sulawesi Selatan yang diselenggarakan oleh Yayasan Almarisah Madani. `,
        `Surat keputusan Perubahan Bentuk diserahkan langsung oleh Ketua LLDIKTI Wilayah IX, Drs.Andi Lukman kepada Ketua Yayasan Almarisah Madani, Drs. H. Sahibuddin A. Gani, apt. selaku Badan Penyelenggara pada tanggal 08 September 2023. Pada tanggal yang sama, Rektor pertama Universitas Almarisah Madani, Dr. Nursamsiar, M.Si dilantik untuk periode 2023-2028.
        `,
        `Dalam pidatonya, Rektor Universitas Almarisah Madani menyebutkan bahwa Universitas Almarisah  Madani  akan memberikan pendidikan yang relevan, inovatif, dan berorientasi pada nilai, serta memfasilitasi penelitian yang mendorong perkembangan ilmu pengetahuan dan masyarakat. Kami ingin berkolaborasi dengan komunitas lokal dan global untuk mengatasi tantangan-tantangan global dan mewujudkan visi kami bersama. 
        `,
      ],
    },
  },
  {
    img: artikel2p1,
    titleId: "Mahasiswa Universitas Almarisah Madani Meraih Gold Medal pada Ajang Indonesia International Invention",
    titleEn: "Mahasiswa Universitas Almarisah Madani Meraih Gold Medal pada Ajang Indonesia International Invention",
    link: "/artikel/mahasiswa-universitas-almarisah-madani-meraih-gold-medal-pada-ajang-indonesia-international-inventio",
    details: {
      author: "Admin UNIVERAL",
      date: "18 September 2023",
      textId: [
        `Mahasiswa Fakultas kesehatan Universitas Almarisah Madani meraih Gold Medal pada ajang Indonesia International Invention Expo 2023 yang di selenggarakan di Semarang pada tanggal 25-28 Agustus 2023.`,
        `Ibu  apt. Amriani Sapra, S.Farm., M.Si selaku Dosen Farmasi Universitas  Almarisah  Madani mendampingi tiga Mahasiswa yaitu Andi Daffa Alifah Abbas, Awwalindah Suarhap, dan Muhammad Fadly Noor Alwani untuk projek Cosmaceutical Cream Nanotechnology Innovation : M. calabura Extract-loaded Transfersome as Dermal Antiaging dalam ajang Indonesia International Invention Expo 2023 dan berhasil meraih Gold Medal serta Best Poster.`,
      ],
      textEn: [
        `Mahasiswa Fakultas kesehatan Universitas Almarisah Madani meraih Gold Medal pada ajang Indonesia International Invention Expo 2023 yang di selenggarakan di Semarang pada tanggal 25-28 Agustus 2023.`,
        `Ibu  apt. Amriani Sapra, S.Farm., M.Si selaku Dosen Farmasi Universitas  Almarisah  Madani mendampingi tiga Mahasiswa yaitu Andi Daffa Alifah Abbas, Awwalindah Suarhap, dan Muhammad Fadly Noor Alwani untuk projek Cosmaceutical Cream Nanotechnology Innovation : M. calabura Extract-loaded Transfersome as Dermal Antiaging dalam ajang Indonesia International Invention Expo 2023 dan berhasil meraih Gold Medal serta Best Poster.`,
      ],
    },
  },
  {
    img: artikel4,
    titleId:"Universitas Almarisah Madani dan Unhas Tv Melakukan Kerjasama Dalam Bidang Pendidikan",
    titleEn:"Almarisah Madani University and UNHAS TV Collaborate in Education",
    link: "/artikel/universitas-almarisah-madani-dan-unhas-tv-melakukan-kerjasama-dalam-bidang-pendidikan",
    details: {
      author: "Admin UNIVERAL",
      date: "22 Maret 2024",
      textId: [
        "Universitas Almarisah Madani (UNIVERAL) dan UNHAS TV sepakat melakukan kerjasama dalam bidang pendidikan melalui penandatangan Memorandum of Understanding (MoU) antara Rektor Universitas Almarisah Madani Dr. Nursamsiar, M.Si dan Direktur Utama UNHAS TV Yusran Darmawan MA, M.Si di kampus UNIVERAL Jl. Perintis Kemerdekaan KM 13,7 Makassar, Jumat (22/3/2024).",
        "Universitas Almarisah Madani (UNIVERAL) sebagai lembaga pendidikan yang berkomitmen dalam menyediakan pendidikan berkualitas tinggi dengan fokus pada pengembangan karakter, Ilmu Pengetahuan, Teknologi, Seni dan Budaya. Di sisi lain, UNHAS TV merupakan salah satu stasiun televisi perguruan tinggi yang dioperasikan oleh Universitas Hasanuddin, yang berfokus pada penyiaran program-program edukatif, berita, dan budaya.",
        "Kerjasama antara Universitas Almarisah Madani dan UNHAS TV muncul sebagai respons terhadap kebutuhan akan pendekatan pendidikan yang lebih holistik, yang tidak hanya fokus pada penyampaian materi akademik, tetapi juga memperhatikan aspek promosi dan kegiatan ekstrakurikuler. Adapun tujuan dari kerjasama ini adalah:",
        "1. Pengembangan Konten Edukatif: Kerjasama ini bertujuan untuk menghasilkan konten-konten edukatif yang berkualitas dan bermanfaat bagi masyarakat. Melalui UNHAS TV, Universitas Almarisah Madani dapat menyebarkan materi-materi pembelajaran yang dikemas secara menarik dan mudah dipahami.",
        "2. Promosi Institusi: Kerjasama dengan UNHAS TV diharapkan dapat menjembatani UNIVERAL yang baru berubah bentuk agar lebih dikenal oleh masyarakat serta dapat menjangkau audiens yang lebih luas, termasuk calon mahasiswa potensial.",
        "3. Meningkatkan Reputasi: Dengan berkolaborasi dengan UNHAS TV, UNIVERAL dapat meningkatkan reputasinya sebagai lembaga pendidikan yang inovatif dan progresif. Partisipasi dalam produksi konten televisi edukatif dapat memberikan citra positif kepada masyarakat tentang komitmen UNIVERAL terhadap pendidikan dan pengembangan masyarakat.",
        "Melalui kerjasama ini juga, Universitas Almarisah Madani berharap akan memberikan dampak positif bagi proses pembelajaran di kelas dengan terciptanya metode pembelajaran yang lebih kreatif dan inovatif.",
      ],
      textEn: [
        "Almarisah Madani University (UNIVERAL) and UNHAS TV have agreed to collaborate in the field of education through the signing of a Memorandum of Understanding (MoU) between the Rector of Almarisah Madani University, Dr. Nursamsiar, M.Si, and the CEO of UNHAS TV, Yusran Darmawan MA, M.Si, at the UNIVERAL campus on Jl. Perintis Kemerdekaan KM 13.7, Makassar, on Friday (22/3/2024).",
        "Almarisah Madani University (UNIVERAL) is an educational institution committed to providing high-quality education with a focus on character development, science, technology, arts, and culture. On the other hand, UNHAS TV is one of the university television stations operated by Hasanuddin University, focusing on broadcasting educational programs, news, and cultural content.",
        "The collaboration between Almarisah Madani University and UNHAS TV arises in response to the need for a more holistic approach to education, which not only focuses on delivering academic materials but also considers promotional aspects and extracurricular activities. The objectives of this collaboration are as follows:",
        "1. Development of Educational Content: This collaboration aims to produce high-quality and beneficial educational content for the community. Through UNHAS TV, Almarisah Madani University can disseminate learning materials packaged in an attractive and easily understandable manner.",
        "2. Institutional Promotion: Collaboration with UNHAS TV is expected to bridge the newly transformed UNIVERAL to be better known by the public and reach a wider audience, including potential students.",
        "3. Enhancing Reputation: By collaborating with UNHAS TV, UNIVERAL can enhance its reputation as an innovative and progressive educational institution. Participation in the production of educational television content can create a positive image among the public regarding UNIVERAL's commitment to education and community development.",
        "Through this collaboration, Almarisah Madani University also hopes to have a positive impact on the classroom learning process by creating more creative and innovative teaching methods.",
      ],
    },
  },
  {
    img: artikel3,
    titleId: "Universitas Almarisah Madani Telah Menetapkan Standar yang Tinggi Dalam Pendidikan Farmasi di Indonesia Timur",
    titleEn: "Almarisah Madani University has set high standards in Pharmacy education in Eastern Indonesia",
    link: "/artikel/universitas-almarisah-madani-telah-menetapkan-standar-yang-tinggi-dalam-pendidikan-farmasi-di-indonesia-timur",
    details: {
      author: "Admin UNIVERAL",
      date: "30 Maret 2024",
      textId: [
        "Kesuksesan dalam mencetak lulusan yang siap bersaing di industri Farmasi tidak hanya menjadi kebanggaan bagi institusi ini sendiri, tetapi juga memberikan kontribusi besar bagi kemajuan industri Farmasi secara keseluruhan.",
        "Program Studi Pendidikan Profesi Apoteker (PSPPA) Universitas Almarisah Madani berhasil mencapai angka kelulusan sebesar 97,67% pada Ujian Kompetensi Mahasiswa Program Profesi Apoteker Indonesia (UKMPPAI) tahun 2024 dan menjadikan UNIVERAL sebagai perguruan tinggi dengan persentase lulus tertinggi se Indonesia Timur. Pencapaian ini bukan merupakan pertama kalinya, karena sebelumnya UNIVERAL juga sudah pernah meraih prestasi lulusan tertinggi pada tahun 2023.",
        "Dibalik kesuksesan ini, ada upaya kolektif yang kuat dari Fakultas, Staf, Dosen dan tentu saja Mahasiswa. Para pengajar yang berkualitas dan berpengalaman di Universitas Almarisah Madani tidak hanya menyediakan pembelajaran yang berkualitas tinggi, tetapi juga menjadi mentor dan menginspirasi bagi para mahasiswa. Dengan pendekatan pembelajaran yang inovatif dan berorientasi pada praktik, mahasiswa dilatih untuk menjadi pemimpin masa depan yang berdaya saing tinggi di berbagai bidang.",
        "Tak kalah pentingnya, atmosfer akademik yang inklusif dan mendukung di Universitas Almarisah Madani turut berkontribusi pada kesuksesan mahasiswa. Dengan mempromosikan kerjasama, toleransi, dan penghargaan terhadap keberagaman, universitas ini menciptakan lingkungan yang memotivasi para mahasiswa untuk meraih prestasi tertinggi.",
        "Keberhasilan ini juga menjadi motivasi bagi universitas Almarisah Madani untuk terus meningkatkan kualitas pendidikan dan memastikan bahwa setiap mahasiswa mendapatkan kesempatan terbaik untuk sukses dalam karir mereka bukan hanya di bidang Farmasi tapi pada bidang-bidang yang lainnya.",
        "Namun, prestasi ini bukanlah akhir dari perjalanan. Universitas Almarisah Madani terus berkomitmen untuk terus meningkatkan mutu pendidikan dan memberikan kontribusi yang berarti bagi masyarakat. Dengan memanfaatkan teknologi dan inovasi terbaru, universitas Almarisah Madani berusaha untuk tetap relevan dan responsif terhadap perkembangan zaman.",
      ],
      textEn: [
        "Success in producing graduates ready to compete in the Pharmacy industry is not only a source of pride for the institution itself, but also makes a significant contribution to the advancement of the Pharmacy industry as a whole.",
        "The Pharmacy Professional Education Program (PSPPA) at Almarisah Madani University achieved a graduation rate of 97.67% in the Indonesian Pharmacist Professional Program Competency Examination (UKMPPAI) in 2024, making UNIVERAL the university with the highest pass percentage in Eastern Indonesia. This achievement is not the first, as UNIVERAL had previously achieved the highest graduate performance in 2023.",
        "Behind this success lies a strong collective effort from the Faculty, Staff, Lecturers, and of course, the Students. The quality and experienced educators at Almarisah Madani University not only provide high-quality education but also serve as mentors and inspirations to the students. Through innovative and practice-oriented teaching approaches, students are trained to become future leaders with high competitiveness in various fields.",
        "Equally important, the inclusive and supportive academic atmosphere at Almarisah Madani University contributes to the success of the students. By promoting collaboration, tolerance, and appreciation of diversity, the university creates an environment that motivates students to achieve the highest performance.",
        "This success also serves as motivation for Almarisah Madani University to continue improving the quality of education and ensuring that every student has the best opportunity to succeed in their careers not only in pharmacy but also in other fields.",
        "However, this achievement is not the end of the journey. Almarisah Madani University remains committed to continuously improving the quality of education and making meaningful contributions to society. By utilizing the latest technology and innovation, Almarisah Madani University strives to remain relevant and responsive to the changing times.",
      ],
    },
  },
  {
    img: artikel5,
    titleId: "Mahasiswa Universitas Almarisah Madani Raih Penghargaan Terbaik di Ajang Online Science Project Competition (OSPC) 2024",
    titleEn: "Students of Almarisah Madani University Win Best Award at Online Science Project Competition (OSPC) 2024",
    link: "/artikel/mahasiswa-universitas-almarisah-madani-raih-penghargaan-terbaik-di-ajang-online-science-project-competition-ospc-2024",
    details: {
      author: "Admin UNIVERAL",
      date: "19 Mei 2024",
      textId: [
        "Tim mahasiswa Universitas Almarisah Madani (Univeral) berhasil menorehkan prestasi gemilang dengan meraih penghargaan terbaik pada ajang bergengsi Online Science Project Competition (OSPC) 2024. Ajang ini merupakan kompetisi ilmiah internasional yang diikuti oleh berbagai universitas dari seluruh Indonesia.",
        "Tim dari Univeral terdiri dari tiga mahasiswa berprestasi, yaitu Musdalifah, Adelia Dwiyanti Budi, dan Radiatusnaini. Mereka berhasil menonjol di antara para pesaing lainnya berkat proyek inovatif yang mereka kembangkan. Dibawah bimbingan apt. Ismail, S. Farm., M.Sc, tim ini mampu menghasilkan penelitian yang tidak hanya menarik tetapi juga memiliki dampak nyata bagi perkembangan ilmu pengetahuan.",
        "Prestasi ini juga menunjukkan bahwa universitas ini mampu bersaing dan unggul di tingkat Nasional maupun internasional, serta berkomitmen dalam mendukung pengembangan penelitian dan inovasi mahasiswa.",
        "Dengan pencapaian ini, Universitas Almarisah Madani semakin mengukuhkan posisinya sebagai lembaga pendidikan yang unggul dan inovatif. Semoga kedepannya semakin banyak karya- karya ilmiah yang dihasilkan oleh mahasiswa dan dosen yang dapat membawa manfaat bagi masyarakat luas.",
      ],
      textEn: [
        "The student team from Almarisah Madani University (Univeral) has achieved remarkable success by winning the best award at the prestigious Online Science Project Competition (OSPC) 2024. This event is an international scientific competition participated in by various universities from all over Indonesia.",
        "The Univeral team consists of three outstanding students: Musdalifah, Adelia Dwiyanti Budi, and Radiatusnaini. They managed to stand out among the other competitors thanks to the innovative project they developed. Under the guidance of apt. Ismail, S. Farm., M.Sc, this team was able to produce research that is not only interesting but also has a tangible impact on the advancement of science.",
        "This achievement also demonstrates that the university is capable of competing and excelling at both the national and international levels, as well as being committed to supporting the development of student research and innovation.",
        "With this accomplishment, Almarisah Madani University is further cementing its position as an outstanding and innovative educational institution. It is hoped that in the future, more scientific works will be produced by students and lecturers that can benefit the wider community.",
      ],
    },
  },
  {
    img: artikel6,
    titleId: "Universitas Almarisah Madani Raih Sorotan di Pekan Ilmiah Mahasiswa Nasional (PIMNAS) 2024",
    titleEn: "Almarisah Madani University Gains Spotlight at National Student Science Week (PIMNAS) 2024",
    link: "/artikel/universitas-almarisah-madani-raih-sorotan-di-pekan-ilmiah-mahasiswa-nasional-pimnas-2024",
    details: {
      author: "Admin UNIVERAL",
      date: "19 Mei 2024",
      textId: [
        "Universitas Almarisah Madani kembali mengukir prestasi gemilang di kancah akademik nasional dengan meraih sorotan pada Pekan Ilmiah Mahasiswa Nasional (PIMNAS) 2024. Salah satu pencapaian terbesar yang diraih oleh universitas ini adalah keberhasilannya dalam memperoleh skema pendanaan terbanyak dalam Program Kreativitas Mahasiswa (PKM) di klaster 5.",
        "PIMNAS adalah ajang kompetisi ilmiah tahunan yang sangat prestisius di Indonesia, di mana mahasiswa dari berbagai universitas bersaing untuk menampilkan hasil penelitian dan inovasi mereka. Pada tahun ini, Universitas Almarisah Madani berhasil menjadi pusat perhatian dengan dominasi mereka di klaster 5 dengan skema pendanaan terbanyak untuk Program Kreativitas Mahasiswa, yang merupakan bukti nyata dari kualitas dan potensi mahasiswa serta dosen pembimbing dalam mengembangkan ide-ide kreatif dan inovatif.",
        "Prestasi ini tidak hanya menunjukkan kualitas akademik dari Universitas Almarisah Madani, tetapi juga komitmen kuat universitas dalam mendukung pengembangan ilmu pengetahuan dan inovasi di kalangan mahasiswanya.",
        "Keberhasilan ini diharapkan dapat menjadi inspirasi bagi seluruh civitas akademika di Universitas Almarisah Madani untuk terus berkarya dan berinovasi. Dengan dukungan dan fasilitas yang memadai, universitas ini bertekad untuk terus mencetak mahasiswa-mahasiswa berprestasi yang mampu bersaing di tingkat nasional maupun internasional.",
      ],
      textEn: [
        "Almarisah Madani University has once again achieved remarkable success on the national academic stage by gaining the spotlight at the National Student Science Week (PIMNAS) 2024. One of the university's greatest achievements this year is securing the highest number of funding schemes in the Student Creativity Program (PKM) in Cluster 5.",
        "PIMNAS is a highly prestigious annual scientific competition in Indonesia, where students from various universities compete to showcase their research and innovations. This year, Almarisah Madani University captured significant attention with their dominance in Cluster 5, obtaining the highest number of funding schemes for the Student Creativity Program.",
        "This achievement is a testament to the quality and potential of the students and their faculty advisors in developing creative and innovative ideas.This accomplishment not only highlights the academic quality of Almarisah Madani University but also underscores the university's strong commitment to supporting the development of science and innovation among its students.",
        "This success is expected to inspire the entire academic community at Almarisah Madani University to continue producing creative and innovative work. With adequate support and facilities, the university is determined to keep producing outstanding students who can compete at both national and international levels.",
      ],
    },
  },
  {
    img: artikel7,
    titleId: "Mahasiswa Universitas Almarisah Madani Lolos Seleksi Tingkat Nasional dalam National University Debating Championship",
    titleEn: "Students of Almarisah Madani University Pass National Selection in the National University Debating Championship",
    link: "/artikel/mahasiswa-universitas-almarisah-madani-lolos-seleksi-tingkat-nasional-dalam-national-university-debating-championship",
    details: {
      author: "Admin UNIVERAL",
      date: "19 Mei 2024",
      textId: [
        "Universitas Almarisah Madani kembali menorehkan prestasi gemilang melalui dua mahasiswanya, Andi Daffah Aliran Abbas dan Dewi Fortuna. Kedua mahasiswa Jurusan Farmasi ini berhasil lolos seleksi tingkat nasional dalam ajang bergengsi National University Debating Championship (NUDC). Keberhasilan mereka tidak hanya membawa kebanggaan bagi pribadi, tetapi juga mengharumkan nama universitas di kancah nasional.",
        "Andi Daffah Aliran Abbas dan Dewi Fortuna menunjukkan kemampuan debat yang luar biasa selama seleksi tingkat regional. Mereka berhasil mengungguli banyak tim dari berbagai universitas terkemuka. Dengan argumentasi yang kuat, retorika yang tajam, dan pengetahuan yang luas, mereka mampu menjawab dan menghadapi berbagai topik debat yang diajukan dengan cemerlang.",
        "NUDC adalah kompetisi debat antar universitas yang diadakan setiap tahun oleh LLDIKTI. Kompetisi ini bertujuan untuk meningkatkan kemampuan berpikir kritis, keterampilan berkomunikasi, dan memperluas wawasan mahasiswa. Proses seleksi yang ketat dan persaingan yang sengit menjadikan keberhasilan Andi dan Dewi sebagai prestasi yang sangat berarti.",
      ],
      textEn: [
        "Almarisah Madani University has once again achieved a remarkable feat through its students, Andi Daffah Aliran Abbas and Dewi Fortuna. Both students from the Pharmacy Department have successfully qualified for the national level in the prestigious National University Debating Championship (NUDC). Their achievement not only brings personal pride but also elevates the university's name on a national scale.",
        "Andi Daffah Aliran Abbas and Dewi Fortuna demonstrated outstanding debating skills during the regional selection. They managed to outperform many teams from various renowned universities. With strong arguments, sharp rhetoric, and extensive knowledge, they were able to address and tackle various debate topics brilliantly.",
        "NUDC is an inter-university debate competition held annually by LLDIKTI. This competition aims to enhance critical thinking skills, communication abilities, and broaden students' horizons. The rigorous selection process and intense competition make Andi and Dewi's success a significant achievement.",
      ],
    },
  },
  {
    img: artikel8,
    titleId: "15 Tim Universitas Almarisah Madani Lolos Pendanaan Program Penelitian 2024",
    titleEn: "15 Teams from Universitas Almarisah Madani Secure Funding in the 2024 Research Program",
    link: "/artikel/15-tim-universitas-almarisah-madani-lolos-pendanaan-program-penelitian-2024",
    details: {
      author: "Admin UNIVERAL",
      date: "19 Juni 2024",
      textId: [
        "Sebanyak 14 tim penelitian dan 1 tim pengabdian kepada masyarakat dari universitas Almarisah Madani berhasil lolos dalam program pendanaan penelitian tahun 2024 yang diselenggarakan oleh Direktorat Jenderal Pendidikan Tinggi, Riset, dan Teknologi melalui Direktorat Riset, Teknologi, dan Pengabdian kepada Masyarakat (DRTPM). Keberhasilan ini bukan hanya menjadi bukti kualitas dan dedikasi para dosen dalam mengembangkan penelitian yang inovatif dan relevan, tetapi juga mencerminkan komitmen kuat Universitas Almarisah Madani dalam mendukung kegiatan akademik dan riset.",
        "Kepala Lembaga Penelitian dan Pengabdian Kepada Masyarakat Universitas Almarisah Madani, Ibu Dr. Nur Khairi, menyampaikan rasa bangganya atas pencapaian ini, keberhasilan ini adalah hasil dari kerja keras dan komitmen para dosen kita yang terus berupaya menghasilkan penelitian berkualitas tinggi yang dapat memberikan kontribusi nyata bagi masyarakat.Proposal yang diajukan mencakup berbagai bidang, mulai dari ilmu pengetahuan dan teknologi, sosial humaniora, hingga pengabdian kepada masyarakat yang bertujuan untuk meningkatkan kesejahteraan dan kualitas hidup.",
        'Rektor Universitas Almarisah Madani, Dr. Nursamsiar, M.Si juga menyampaikan apresiasinya kepada para dosen. "Saya sangat bangga dengan pencapaian ini. Ini adalah bukti bahwa Universitas Almarisah Madani memiliki potensi besar dalam bidang penelitian dan pengabdian kepada masyarakat. Keberhasilan ini tidak hanya mengharumkan nama universitas, tetapi juga menunjukkan bahwa kita mampu bersaing di tingkat nasional bahkan tingkat Internasional. Universitas Almarisah Madani akan terus berkomitmen untuk mendukung para dosen dan mahasiswa dalam melakukan penelitian yang inovatif dan bermanfaat bagi masyarakat”',
        "Selamat kepada Bapak dan Ibu dosen yang luar biasa atas pencapaian ini. Semoga prestasi ini dapat terus memotivasi dan menginspirasi seluruh civitas akademika Universitas Almarisah Madani untuk terus berkarya dan berkontribusi bagi kemajuan bangsa.",
      ],
      textEn: [
        "A total of 14 research teams and 1 community service team from Universitas Almarisah Madani have successfully secured funding in the 2024 research program organized by the Directorate General of Higher Education, Research, and Technology through the Directorate of Research, Technology, and Community Service (DRTPM). This achievement not only demonstrates the quality and dedication of the lecturers in developing innovative and relevant research but also reflects the strong commitment of Universitas Almarisah Madani in supporting academic and research activities.",
        'The Head of the Research and Community Service Institute at Universitas Almarisah Madani, Dr. Nur Khairi, expressed her pride in this accomplishment. "This success is the result of the hard work and commitment of our lecturers who continuously strive to produce high-quality research that can make a real contribution to society. The proposals submitted cover various fields, ranging from science and technology, social humanities, to community service aimed at improving welfare and quality of life," she stated.',
        'The Rector of Universitas Almarisah Madani, Dr. Nursamsiar, M.Si, also expressed his appreciation to the lecturers. "I am very proud of this achievement. This is evidence that Universitas Almarisah Madani has great potential in the fields of research and community service. This success not only brings honor to the university but also shows that we are capable of competing at the national and even international levels. Universitas Almarisah Madani will continue to commit to supporting lecturers and students in conducting innovative and beneficial research for society."',
        "Congratulations to the outstanding lecturers on this achievement. May this success continue to motivate and inspire the entire academic community of Universitas Almarisah Madani to keep creating and contributing to the advancement of the nation."
      ],
    },
  },
  {
    img: artikel14,
    titleId: "Universitas Almarisah Madani dan Rumah Sakit Otak & Jantung Royal Biringkanaya resmi menandatangani Memorandum of Understanding (MoU) yang bertujuan untuk meningkatkan kerja sama dalam bidang pendidikan dan pelayanan kesehatan",
    titleEn: "Almarisah Madani University and Royal Biringkanaya Brain & Heart Hospital Officially Sign Memorandum of Understanding (MoU) to Enhance Cooperation in Education and Health Services",
    link: "/artikel/universitas-almarisah-madani-dan-rumah-sakit-otak-jantung-royal-biringkanaya-resmi-menandatangani-memorandum-of-understanding-mou-yang-bertujuan-untuk-meningkatkan-kerja-sama-dalam-bidang-pendidikan-dan-pelayanan-kesehatan",
    details: {
      author: "Admin UNIVERAL",
      date: "30 Juli 2024",
      textId: [
        "Pada tanggal 23 Juli 2024, Universitas Almarisah Madani dan Rumah Sakit Otak & Jantung Royal Biringkanaya resmi menandatangani Memorandum of Understanding (MoU) yang bertujuan untuk meningkatkan kerja sama dalam bidang pendidikan dan pelayanan kesehatan. Penandatanganan MoU ini dilakukan oleh Rektor Universitas Almarisah Madani, Dr. Nursamsiar, M.Si, dan Direktur Utama Rumah Sakit Otak & Jantung Royal Biringkanaya, Mohammad Ahsan dalam sambutannya, Dr. Nursamsiar menekankan pentingnya kolaborasi ini untuk meningkatkan kualitas pendidikan di bidang kesehatan serta pelayanan medis yang lebih baik. “Kerja sama ini merupakan langkah strategis untuk memajukan pendidikan kesehatan dan meningkatkan standar pelayanan medis di daerah. Kami sangat antusias untuk mengembangkan berbagai program yang akan menguntungkan kedua belah pihak dan masyarakat luas,” ujar Dr. Nursamsiar.",
        "Sementara itu, Mohammad Ahsan juga menyampaikan apresiasinya terhadap kerja sama ini. “Kami menyambut baik kerjasama ini dengan Universitas Almarisah Madani. Melalui MoU ini, kami berharap dapat mengintegrasikan pengetahuan dan praktik medis terkini dalam program pendidikan serta memperluas kesempatan bagi mahasiswa untuk mendapatkan pengalaman langsung di fasilitas kami,” ungkap Dr. Ahmad.",
        'Dengan penandatanganan MoU ini, Universitas Almarisah Madani dan Rumah Sakit Otak & Jantung Royal Biringkanaya secara resmi memulai langkah baru dalam meningkatkan kualitas pendidikan dan pelayanan kesehatan. Kolaborasi ini diharapkan dapat membawa manfaat yang signifikan bagi kedua belah pihak serta masyarakat secara umum, dengan tujuan akhir untuk menciptakan generasi muda yang lebih siap dan berkompeten di bidang kesehatan.',
        "Kerja sama ini merupakan contoh nyata dari upaya sinergi antara institusi pendidikan dan layanan kesehatan untuk memajukan standar pelayanan medis dan pendidikan di Indonesia, serta memberikan dampak positif yang berkelanjutan bagi kesehatan masyarakat.",
      ],
      textEn: [
        "On July 23, 2024, Almarisah Madani University and Royal Biringkanaya Brain & Heart Hospital officially signed a Memorandum of Understanding (MoU) aimed at enhancing cooperation in the fields of education and health services. The MoU was signed by the Rector of Almarisah Madani University, Dr. Nursamsiar, M.Si, and the President Director of Royal Biringkanaya Brain & Heart Hospital, Mohammad Ahsan.",
        'In his speech, Dr. Nursamsiar emphasized the significance of this collaboration in improving the quality of health education and medical services. "This cooperation is a strategic step to advance health education and elevate the standards of medical services in the region. We are very enthusiastic about developing various programs that will benefit both parties and the wider community," said Dr. Nursamsiar.',
        'Meanwhile, Mohammad Ahsan also expressed his appreciation for the collaboration. "We welcome this partnership with Almarisah Madani University. Through this MoU, we hope to integrate current medical knowledge and practices into educational programs and expand opportunities for students to gain hands-on experience at our facilities," said Mohammad Ahsan.',
        "With the signing of this MoU, Almarisah Madani University and Royal Biringkanaya Brain & Heart Hospital officially embark on a new phase in improving the quality of education and health services. This collaboration is expected to bring significant benefits to both parties and the general public, with the ultimate goal of creating a younger generation that is better prepared and more competent in the field of health.",
        "This cooperation serves as a concrete example of synergy between educational institutions and health services to advance medical and educational standards in Indonesia and to provide a lasting positive impact on public health."
      ],
    },
  },
  {
    img: artikel13,
    titleId: "Universitas Almarisah Madani Teken MOU dengan MAHSTA Malaysia: Langkah Baru dalam Kerja Sama, Kuliah Tamu, dan Pengabdian kepada Masyarakat",
    titleEn: "Almarisah Madani University Signs MOU with MAHSTA Malaysia: A New Step in Collaboration, Guest Lectures, and Community Service",
    link: "/artikel/universitas-almarisah-madani-teken-mou-dengan-mahsta-malaysia-langkah-baru-dalam-kerja-sama-kuliah-tamu-dan-pengabdian-kepada-masyarakat",
    details: {
      author: "Admin UNIVERAL",
      date: "31 Juli 2024",
      textId: [
        "Universitas Almarisah Madani (Univeral) dan Persatuan Pengajar Kesehatan Bersekutu Malaysia (MAHSTA) baru-baru ini menandatangani Memorandum of Understanding (MOU) Bersama dengan 14 perguruan tinggi di wilayah sulawesi Indonesia yang menandai sebuah babak baru dalam kerjasama internasional di bidang pendidikan dan pengabdian masyarakat. Penandatanganan MOU ini dilakukan di Politeknik Kesehatan (Poltekkes) Kementerian Kesehatan Makassar, sebagai bagian dari komitmen bersama untuk meningkatkan kualitas pendidikan dan pelayanan kesehatan di kawasan Asia Tenggara. Penandatangan MoU ini dilakukan pada Tanggal 29 Juni 2024.",
        "Penandatanganan MOU ini merupakan langkah signifikan dalam meningkatkan kualitas pendidikan kesehatan di Indonesia dan Malaysia. Dengan adanya pertukaran pengetahuan dan pengalaman, kedua belah pihak berharap dapat menciptakan sinergi yang akan memperkuat kapasitas akademik dan meningkatkan standar pelayanan kesehatan di wilayah mereka.",
        'Dengan adanya MOU ini, Universitas Almarisah Madani dan MAHSTA berkomitmen untuk melanjutkan upaya-upaya mereka dalam mengembangkan pendidikan dan pelayanan kesehatan. Kerja sama ini diharapkan dapat menjadi model bagi institusi pendidikan lainnya dalam rangka membangun hubungan internasional yang saling menguntungkan dan memberikan dampak positif yang berkelanjutan bagi masyarakat.',
      ],
      textEn: [
        "Almarisah Madani University (Univeral) and the Malaysian Allied Health Sciences Teaching Association (MAHSTA) recently signed a Memorandum of Understanding (MOU) with 14 higher education institutions in the Sulawesi region of Indonesia, marking a new chapter in international collaboration in education and community service. The MOU signing took place at the Health Polytechnic (Poltekkes) of the Ministry of Health in Makassar, as part of a joint commitment to enhancing the quality of education and healthcare services in Southeast Asia. The MOU was signed on June 29, 2024.",
        'The signing of this MOU is a significant step in improving the quality of health education in Indonesia and Malaysia. Through the exchange of knowledge and experience, both parties hope to create a synergy that will strengthen academic capacity and raise the standards of healthcare services in their respective regions.',
        'With this MOU, Almarisah Madani University and MAHSTA are committed to continuing their efforts in developing education and healthcare services. This collaboration is expected to serve as a model for other educational institutions in building mutually beneficial international relationships and providing sustainable positive impacts for the community.',
      ],
    },
  },
  {
    img: artikel15,
    titleId: "Universitas Almarisah Madani Mendapatkan Akreditasi “Baik Sekali” Badan Akreditasi Nasional Perguruan Tinggi (BAN-PT)",
    titleEn: "Almarisah Madani University Receives \"Excellent\" Accreditation from the National Accreditation Agency for Higher Education (BAN-PT)",
    link: "/artikel/universitas-almarisah-madani-mendapatkan-akreditasi-baik-sekali-badan-akreditasi-nasional-perguruan-tinggi-ban-pt",
    details: {
      author: "Admin UNIVERAL",
      date: "30 Juli 2024",
      textId: [
        'Universitas Almarisah Madani (Univeral) dengan bangga mengumumkan pencapaian terbaru mereka dalam bidang pendidikan tinggi. Pada bulan Juli 2024, Univeral resmi mendapatkan akreditasi "Baik Sekali" dari Badan Akreditasi Nasional Perguruan Tinggi (BAN-PT). Pencapaian ini menunjukkan komitmen Univeral untuk terus meningkatkan kualitas pendidikan, penelitian, dan pengabdian kepada masyarakat',
        'Rektor Universitas Almarisah Madani Dr. Nursamsiar, M.Si menyampaikan rasa syukur dan bangganya atas pencapaian ini. "Akreditasi \'Baik Sekali\' dari BAN-PT adalah bukti nyata dari kerja keras seluruh civitas akademika Univeral. Kami berkomitmen untuk terus meningkatkan standar pendidikan dan menciptakan lingkungan akademik yang kondusif bagi mahasiswa," ujar Dr. Nursamsiar, M.Si.',
        'Proses akreditasi yang dilakukan oleh BAN-PT melibatkan penilaian menyeluruh terhadap berbagai aspek universitas, termasuk kurikulum, fasilitas, kualitas dosen, dan program-program pengembangan mahasiswa. Penilaian ini memastikan bahwa institusi pendidikan tinggi memenuhi standar yang ditetapkan dan mampu memberikan pendidikan yang berkualitas tinggi.',
        "Akreditasi Baik Sekali ini merupakan hasil dari kegiatan surveilans yang telah dilaksanakan sebelumnya pada tanggal 27 Juni 2024. Dengan mendapatkan akreditasi \"Baik Sekali\" dari BAN-PT, Universitas Almarisah Madani semakin memperkuat posisinya sebagai salah satu institusi pendidikan tinggi terkemuka di Indonesia. Univeral berkomitmen untuk terus menjaga dan meningkatkan standar kualitas pendidikan yang telah dicapai, serta berkontribusi dalam mencetak generasi muda yang unggul dan siap bersaing di kancah global.",
        "Pencapaian ini juga diharapkan dapat menjadi inspirasi bagi institusi pendidikan lainnya untuk terus berupaya meningkatkan kualitas dan memberikan pendidikan terbaik bagi anak bangsa.",
      ],
      textEn: [
        "Almarisah Madani University (Univeral) proudly announces its latest achievement in higher education. In July 2024, Univeral officially received the \"Excellent\" accreditation from the National Accreditation Agency for Higher Education (BAN-PT). This achievement demonstrates Univeral's commitment to continuously improving the quality of education, research, and community service.",
        'Dr. Nursamsiar, M.Si, the Rector of Almarisah Madani University, expressed gratitude and pride in this achievement. "The \'Excellent\' accreditation from BAN-PT is a tangible proof of the hard work of the entire academic community at Univeral. We are committed to continuously raising the standards of education and creating an academic environment that is conducive to our students," said Dr. Nursamsiar, M.Si.',
        'The accreditation process conducted by BAN-PT involved a comprehensive evaluation of various aspects of the university, including the curriculum, facilities, faculty quality, and student development programs. This assessment ensures that the higher education institution meets the established standards and is capable of providing high-quality education.',
        "The \"Excellent\" accreditation result is the outcome of a surveillance activity that was conducted on June 27, 2024. By obtaining the \"Excellent\" accreditation from BAN-PT, Almarisah Madani University further strengthens its position as one of the leading higher education institutions in Indonesia. Univeral is committed to maintaining and enhancing the quality standards achieved and contributing to the development of outstanding and globally competitive young generations.",
        "This achievement is also expected to inspire other educational institutions to continuously strive to improve quality and provide the best education for the nation's youth."
      ],
    },
  },
  {
    img: artikel16,
    titleId: "Program Studi Pendidikan Profesi Apoteker Universitas Almarisah Madani Menyelenggarakan Kuliah Tamu Tentang “Nutrisi Yang Sehat dan Seimbang Membuat Sistem Imun Berfungsi Optimal”",
    titleEn: "The Pharmacy Professional Education Program at Almarisah Madani University Holds a Guest Lecture on \"Healthy and Balanced Nutrition for Optimal Immune System Function\"",
    link: "/artikel/program-studi-pendidikan-profesi-apoteker-universitas-almarisah-madani-menyelenggarakan-kuliah-tamu-tentang-nutrisi-yang-sehat-dan-seimbang-membuat-sistem-imun-berfungsi-optimal",
    details: {
      author: "Admin UNIVERAL",
      date: "30 Juli 2024",
      textId: [
        "Program Studi Pendidikan Profesi Apoteker di Universitas Almarisah Madani (Univeral) dengan bangga menyelenggarakan kuliah tamu bertajuk “Nutrisi Yang Sehat dan Seimbang Membuat Sistem Imun Berfungsi Optimal.” Acara ini menghadirkan Prof. Dr. apt. Maria Immaculata Iwo, M.S., seorang ahli farmasi dan nutrisi terkemuka yang memberikan wawasan mendalam tentang pentingnya pola makan sehat dalam menjaga dan meningkatkan fungsi sistem imun.",
        "Prof. Maria, dengan pengalaman luasnya dalam penelitian dan praktik di bidang farmasi dan nutrisi, menyampaikan materi yang komprehensif dan informatif kepada para peserta kuliah tamu yang terdiri dari mahasiswa, dosen, dan profesional industri. Beliau menguraikan berbagai aspek penting terkait nutrisi dan kesehatan imun, yang dirangkum dalam beberapa poin utama:",
        '1. Pentingnya Nutrisi Seimbang',
        '2. Peran Vitamin dan Mineral',
        '3. Dampak Pola Makan dan Gaya Hidup',
        '4. Makanan Fungsional',
        "Kuliah tamu ini diikuti dengan sesi tanya jawab yang interaktif, dimana para peserta diberikan kesempatan untuk berdiskusi langsung dengan Prof. Maria. Mahasiswa menunjukkan antusiasme yang tinggi dalam memahami bagaimana mereka dapat menerapkan pola makan sehat dalam kehidupan sehari-hari serta mencari solusi untuk tantangan yang mereka hadapi dalam menjaga keseimbangan nutrisi.",
        "Kuliah tamu ini merupakan bagian dari komitmen berkelanjutan Program Studi Pendidikan Profesi Apoteker di Univeral untuk memberikan pendidikan yang berkualitas dan relevan dengan kebutuhan kesehatan masyarakat. Dengan menghadirkan ahli-ahli terkemuka dan topik-topik yang mendalam, program ini berkomitmen untuk menghasilkan lulusan yang tidak hanya kompeten dalam bidang farmasi tetapi juga memiliki pemahaman luas mengenai aspek-aspek kesehatan lainnya.",
        "Universitas Almarisah Madani terus berupaya untuk menyelenggarakan acara-acara pendidikan yang inspiratif dan bermanfaat, memperkuat posisinya sebagai institusi pendidikan yang unggul dalam bidang kesehatan dan farmasi. Kuliah tamu ini diharapkan dapat memberikan manfaat jangka panjang bagi para peserta dalam menerapkan pengetahuan yang didapat untuk menjaga kesehatan dan kesejahteraan masyarakat.",
      ],
      textEn: [
        "The Pharmacy Professional Education Program at Almarisah Madani University (Univeral) proudly held a guest lecture titled \"Healthy and Balanced Nutrition for Optimal Immune System Function.\" The event featured Prof. Dr. apt. Maria Immaculata Iwo, M.S., a renowned expert in pharmacy and nutrition, who provided in-depth insights on the importance of a healthy diet in maintaining and enhancing immune system function.",
        "Prof. Maria, with her extensive experience in research and practice in the fields of pharmacy and nutrition, delivered comprehensive and informative material to the guest lecture participants, which included students, faculty, and industry professionals. She outlined several key aspects related to nutrition and immune health, summarized in the following main points:",
        '1. The Importance of Balanced Nutrition',
        '2. The Role of Vitamins and Minerals',
        '3. The Impact of Diet and Lifestyle',
        '4. Functional Foods',
        "The guest lecture was followed by an interactive Q&A session, where participants had the opportunity to engage directly with Prof. Maria. Students showed great enthusiasm in understanding how they could apply healthy eating habits in their daily lives and in finding solutions to the challenges they face in maintaining balanced nutrition.",
        "This guest lecture is part of the ongoing commitment of the Pharmacy Professional Education Program at Univeral to provide high-quality education that is relevant to public health needs. By featuring prominent experts and in-depth topics, the program is dedicated to producing graduates who are not only competent in the field of pharmacy but also have a broad understanding of other health aspects.",
        "Almarisah Madani University continues to strive to host inspiring and beneficial educational events, strengthening its position as a leading institution in the fields of health and pharmacy. This guest lecture is expected to provide long-term benefits to participants in applying the knowledge they have gained to maintain public health and well-being.",
      ],
    },
  },
  {
    img: artikel17,
    titleId: "Universitas Almarisah Madani dan Universitas Kitasato Jepang Membuka Babak Baru dalam Kerjasama Internasional",
    titleEn: "Almarisah Madani University and Kitasato University Japan Open a New Chapter in International Cooperation",
    link: "/artikel/universitas-almarisah-madani-dan-universitas-kitasato-jepang-membuka-babak-baru-dalam-kerjasama-internasional",
    details: {
      author: "Admin UNIVERAL",
      date: "30 Juli 2024",
      textId: [
        "Pada hari Selasa, 16 Juli 2024, Universitas Almarisah Madani (Univeral) dan Universitas Kitasato Jepang secara resmi membuka babak baru dalam kerjasama internasional. Penandatanganan Memorandum of Understanding (MoU) yang berlangsung di kampus Kitasato Jepang ini menandai komitmen kedua universitas untuk meningkatkan kolaborasi dalam bidang pendidikan, penelitian, dan pengabdian kepada masyarakat.",
        "Kerjasama ini merupakan langkah strategis untuk memperkuat hubungan akademik dan budaya antara Indonesia dan Jepang. Kami berharap dapat belajar banyak dari Universitas Kitasato, terutama dalam bidang penelitian medis dan bioteknologi. kerjasama ini fokus pada magang dosen dan mahasiswa, penelitian akademik, dan berbagai implementasi kerjasama lainnya, kami berharap kerjasama ini dapat memberikan kontribusi yang signifikan untuk kemajuan pendidikan.",
        'Acara penandatanganan MoU ini dilakukan oleh Wakil Rektor III Wakil Rektor Bidang Riset, Inovasi, Pengembangan dan Kerjasama ibu apt. Besse Hardianti, S.Si, M.Pharm., Sc., Ph.D. Suasana penuh semangat dan optimisme tampak jelas selama acara berlangsung. Para hadirin berharap bahwa kerjasama ini akan memberikan manfaat besar bagi kedua universitas dan berkontribusi pada peningkatan kualitas pendidikan dan penelitian di tingkat internasional.',
        "Dengan ditandatanganinya MoU ini, Universitas Almarisah Madani dan Universitas Kitasato Jepang resmi memulai perjalanan baru dalam kerjasama internasional yang diharapkan dapat memperkuat hubungan bilateral dan membawa dampak positif bagi masyarakat global.",
      ],
      textEn: [
        "On Tuesday, July 16, 2024, Almarisah Madani University (Univeral) and Kitasato University Japan officially opened a new chapter in international cooperation. The signing of the Memorandum of Understanding (MoU) held at the Kitasato University campus in Japan marks the commitment of both universities to enhance collaboration in education, research, and community service.",
        'This cooperation is a strategic step to strengthen academic and cultural relations between Indonesia and Japan. "We hope to learn a lot from Kitasato University, especially in the fields of medical research and biotechnology," said a representative from Almarisah Madani University. The collaboration focuses on faculty and student internships, academic research, and various other cooperative implementations. It is expected that this partnership will contribute significantly to the advancement of education.',
        'The MoU signing event was conducted by the Vice Rector for Research, Innovation, Development, and Cooperation, apt. Besse Hardianti, S.Si, M.Pharm., Sc., Ph.D. The atmosphere was filled with enthusiasm and optimism throughout the event. Attendees hoped that this collaboration would bring great benefits to both universities and contribute to the improvement of education and research quality at an international level.',
        "With the signing of this MoU, Almarisah Madani University and Kitasato University Japan officially embark on a new journey in international cooperation that is expected to strengthen bilateral relations and bring positive impacts to the global community."
      ],
    },
  },
  {
    img: artikel9,
    titleId: "Tim Universitas Almarisah Madani Raih Juara Kedua di Alauddin Pharmaceutical Competition 2024",
    titleEn: 'Almarisah Madani University Achieves Remarkable Success at the "Alauddin Pharmaceutical Competition 2024"',
    link: "/artikel/tim-universitas-almarisah-madani-raih-juara-kedua-di-alauddin-pharmaceutical-competition-2024",
    details: {
      author: "Admin UNIVERAL",
      date: "5 Agustus 2024",
      textId: [
        "Universitas Almarisah Madani baru-baru ini meraih prestasi gemilang di ajang nasional “Alauddin Pharmaceutical Competition 2024.” Tim yang terdiri dari Musdalifah dan Adelia Dwiyanti Budi berhasil meraih juara kedua dalam lomba poster edukasi publik mahasiswa yang diadakan pada acara tersebut.",
        "Lomba ini diadakan untuk meningkatkan kesadaran dan pengetahuan publik mengenai isu-isu kesehatan dan farmasi melalui media visual. Kompetisi ini menarik perhatian banyak peserta dari berbagai perguruan tinggi di seluruh Indonesia, menjadikannya salah satu ajang bergengsi di bidang farmasi.",
        'Musdalifah dan Adelia Dwiyanti Budi berhasil mengembangkan poster yang tidak hanya menarik secara visual, tetapi juga informatif dan edukatif. Poster mereka berhasil menyampaikan pesan yang jelas mengenai pentingnya kesadaran terhadap kesehatan tertentu, serta cara-cara pencegahan yang efektif. Kreativitas dan ketelitian mereka dalam merancang poster mendapat apresiasi tinggi dari para juri yang terdiri dari ahli farmasi dan akademisi terkemuka.',
        "Keberhasilan ini merupakan hasil kerja keras dan dedikasi tim Universitas Almarisah Madani. Karya mereka tidak hanya mencerminkan kualitas akademik yang tinggi, tetapi juga komitmen mereka untuk berkontribusi dalam meningkatkan pengetahuan kesehatan masyarakat.",
        "Prestasi ini diharapkan dapat menjadi inspirasi bagi mahasiswa lainnya untuk terus berinovasi dan berprestasi di bidang yang mereka geluti. Universitas Almarisah Madani terus mendukung dan memotivasi mahasiswanya untuk aktif berpartisipasi dalam berbagai kompetisi akademik dan non-akademik guna meraih pencapaian yang lebih tinggi di masa depan.",
      ],
      textEn: [
        'Almarisah Madani University recently achieved remarkable success at the national event "Alauddin Pharmaceutical Competition 2024." The team, consisting of Musdalifah and Adelia Dwiyanti Budi, secured second place in the student public education poster competition held during the event.',
        'This competition was organized to raise public awareness and knowledge about health and pharmaceutical issues through visual media. It attracted numerous participants from various universities across Indonesia, making it one of the prestigious events in the pharmaceutical field.',
        'Musdalifah and Adelia Dwiyanti Budi developed a poster that was not only visually appealing but also informative and educational. Their poster successfully conveyed a clear message about the importance of awareness regarding specific health issues and effective prevention methods. Their creativity and meticulousness in designing the poster were highly appreciated by the judges, who comprised leading pharmacists and academics.',
        "This achievement is the result of the hard work and dedication of the Almarisah Madani University team. Their work not only reflects high academic quality but also their commitment to contributing to the improvement of public health knowledge.",
        "This success is expected to inspire other students to continue innovating and excelling in their respective fields. Almarisah Madani University continues to support and motivate its students to actively participate in various academic and non-academic competitions to achieve higher accomplishments in the future.",
      ],
    },
  },
  {
    img: artikel10,
    titleId: "Universitas Almarisah Madani Raih Lima Penghargaan Prestisius di PKP2 dan PKM Award 2024",
    titleEn: "Almarisah Madani University Wins Five Prestigious Awards at the PKP2 and PKM Award 2024",
    link: "/artikel/universitas-almarisah-madani-raih-lima-penghargaan-prestisius-di-pkp2-dan-pkm-award-2024",
    details: {
      author: "Admin UNIVERAL",
      date: "20 Agustus 2024",
      textId: [
        "Universitas Almarisah Madani (Univeral) telah mencatatkan prestasi luar biasa pada acara PKP2 dan PKM Award tahun 2024. Dalam acara bergengsi tersebut, Univeral berhasil meraih lima penghargaan sekaligus, menunjukkan komitmen dan kualitas tinggi dalam bidang penelitian dan pengembangan mahasiswa. Berikut adalah rincian penghargaan yang berhasil diraih oleh universitas ini:",
        "1. Dosen Pendamping Kelompok Mahasiswa Pendanaan dan Insentif Terbanyak",
        "Penghargaan ini diberikan kepada dosen pendamping yang telah berhasil memperoleh pendanaan dan insentif terbanyak untuk kelompok mahasiswa. Ini adalah pengakuan atas dedikasi dan kerja keras dosen dalam mendukung penelitian dan proyek mahasiswa, serta kontribusi signifikan mereka dalam mengembangkan potensi akademik dan kreatif mahasiswa.",
        '2. PT Terproduktif pada PKM Kluster V',
        'Universitas Almarisah Madani dinyatakan sebagai perguruan tinggi terproduktif pada PKM Kluster V. Penghargaan ini mengapresiasi jumlah dan kualitas produk penelitian dan pengabdian masyarakat yang dihasilkan oleh universitas, menunjukkan bahwa Univeral memimpin dalam menghasilkan karya ilmiah dan kontribusi signifikan dalam komunitas akademik.',
        "3. PT Penerima Pendanaan Terbanyak pada PKM Kluster V",
        "Univeral juga meraih penghargaan sebagai perguruan tinggi dengan penerima pendanaan terbanyak pada PKM Kluster V. Ini menandakan keberhasilan Univeral dalam memperoleh dukungan finansial untuk berbagai proyek penelitian, yang memungkinkan mahasiswa dan dosen untuk mengeksplorasi ide-ide inovatif dan melakukan penelitian yang berdampak.",
        "4. PT Penerima Insentif Terbanyak pada PKM Kluster V",
        "Selain pendanaan, Univeral juga mendapatkan penghargaan sebagai perguruan tinggi penerima insentif terbanyak pada PKM Kluster V. Penghargaan ini mencerminkan apresiasi terhadap kualitas dan relevansi penelitian yang dilakukan di Univeral, serta keberhasilan universitas dalam memenuhi standar tinggi yang ditetapkan oleh panitia PKM.",
        "5. Kelompok Mahasiswa Terkreatif pada Bidang Riset Eksakta PKM Skema Pendanaan Tahun 2024",
        "Salah satu kelompok mahasiswa dari Univeral berhasil meraih penghargaan sebagai kelompok mahasiswa terkreatif di bidang riset eksakta pada PKM skema pendanaan tahun 2024. Penghargaan ini mengakui inovasi dan kreativitas kelompok mahasiswa dalam mengembangkan penelitian yang tidak hanya relevan tetapi juga memberikan solusi baru dalam bidang eksakta.",
        "Keberhasilan Universitas Almarisah Madani dalam meraih lima penghargaan prestisius ini adalah cerminan dari dedikasi dan komitmen seluruh civitas akademika Univeral dalam mendorong kemajuan penelitian dan pengembangan. Prestasi ini juga memperkuat posisi Univeral sebagai salah satu institusi pendidikan tinggi terkemuka yang terus berkontribusi dalam dunia akademik dan penelitian di Indonesia.",
        "Dengan pencapaian ini, Univeral tidak hanya mengukuhkan diri sebagai pemimpin dalam bidang penelitian dan pengabdian masyarakat, tetapi juga menginspirasi perguruan tinggi lainnya untuk terus berinovasi dan berprestasi.",
      ],
      textEn: [
        "Almarisah Madani University (Univeral) has achieved outstanding results at the PKP2 and PKM Award 2024. At this prestigious event, Univeral garnered five awards, demonstrating its commitment to and high quality in student research and development. Here are the details of the awards won by the university:",
        "1. Most Funding and Incentives for Student Research Group Mentors",
        "This award is given to the mentor who has successfully secured the most funding and incentives for student research groups. It recognizes the dedication and hard work of the mentor in supporting student research and projects, as well as their significant contribution to developing students' academic and creative potential.",
        '2. Most Productive Institution in PKM Cluster V',
        'Almarisah Madani University was named the most productive institution in PKM Cluster V. This award acknowledges the quantity and quality of research and community service products produced by the university, showing that Univeral leads in generating scholarly work and making significant contributions to the academic community.',
        "3. Institution with the Most Funding Recipients in PKM Cluster V",
        "Univeral also received the award for being the institution with the most funding recipients in PKM Cluster V. This signifies Univeral's success in obtaining financial support for various research projects, enabling students and faculty to explore innovative ideas and conduct impactful research.",
        "4. Institution with the Most Incentives Received in PKM Cluster V",
        "In addition to funding, Univeral was awarded for receiving the most incentives in PKM Cluster V. This award reflects the appreciation for the quality and relevance of the research conducted at Univeral, as well as the university’s success in meeting the high standards set by the PKM committee.",
        "5. Most Creative Student Group in Exact Sciences Research in PKM Funding Scheme 2024",
        "One of Univeral’s student groups won the award for being the most creative in the field of exact sciences research in the PKM funding scheme for 2024. This award recognizes the innovation and creativity of the student group in developing research that is not only relevant but also provides new solutions in the exact sciences field.",
        "The success of Almarisah Madani University in winning these five prestigious awards reflects the dedication and commitment of the entire academic community at Univeral in advancing research and development. This achievement also strengthens Univeral's position as one of the leading higher education institutions that continues to contribute significantly to the academic and research landscape in Indonesia.",
        "With this achievement, Univeral not only consolidates its role as a leader in research and community service but also inspires other institutions to continue innovating and excelling.",
      ],
    },
  },
  {
    img: artikel11,
    titleId: "Universitas Almarisah Madani Meraih Prestasi Gemilang di Anugerah LLDIKTI Wilayah IX",
    titleEn: "Almarisah Madani University Achieves Outstanding Success at the LLDIKTI Region IX Awards",
    link: "/artikel/universitas-almarisah-madani-meraih-prestasi-gemilang-di-anugerah-lldikti-wilayah-ix",
    details: {
      author: "Admin UNIVERAL",
      date: "20 Agustus 2024",
      textId: [
        "Pada tanggal 18 Agustus 2024, Universitas Almarisah Madani (Univeral) mencatatkan prestasi gemilang dalam acara Anugerah LLDIKTI Wilayah IX. Acara yang diadakan untuk mengapresiasi pencapaian luar biasa dalam dunia pendidikan tinggi di Indonesia ini memberikan dua penghargaan penting kepada universitas tersebut, menegaskan komitmen dan kontribusi Univeral dalam sektor pendidikan tinggi.",
        'Penghargaan "Tokoh Non Pendidikan yang Peduli Pendidikan"',
        'Salah satu penghargaan paling menonjol yang diterima Univeral adalah "Tokoh Non Pendidikan yang Peduli Pendidikan." Penghargaan ini diberikan kepada Ketua Yayasan Almarisah Madani bapak Drs. Sahibuddin A.Gani, Apt. yang telah menunjukkan kepedulian dan dedikasi luar biasa dalam memajukan pendidikan di Indonesia, meskipun beliau bukan seorang praktisi pendidikan langsung. Kepemimpinan dan dukungan beliau terhadap Universitas Almarisah Madani serta inisiatif pendidikan lainnya telah memberikan dampak signifikan bagi kemajuan pendidikan tinggi di wilayah ini.',
        'Penghargaan "Pimpinan Berprestasi dan Inovatif Perguruan Tinggi Swasta"',
        'Universitas Almarisah Madani juga meraih penghargaan untuk "Pimpinan Berprestasi dan Inovatif Perguruan Tinggi Swasta." Penghargaan ini mengakui kepemimpinan yang berprestasi dan inovatif pimpinanan Univeral ibu Dr. Nursamsiar, M.Si dalam pengelolaan perguruan tinggi swasta. Ini mencerminkan keberhasilan Univeral dalam menerapkan praktik terbaik, inovasi, dan strategi yang telah berkontribusi pada kemajuan universitas serta memberikan dampak positif bagi komunitas akademik dan masyarakat luas.',
        'Kontribusi dan Komitmen Univeral dalam Pendidikan Tinggi',
        'Keberhasilan Universitas Almarisah Madani dalam meraih dua penghargaan bergengsi ini adalah hasil dari komitmen yang kuat dan upaya berkelanjutan dalam memajukan pendidikan tinggi. Melalui kepemimpinan yang visioner, inovasi dalam pengelolaan, serta dukungan yang konsisten terhadap inisiatif pendidikan, Univeral terus berperan sebagai salah satu institusi pendidikan tinggi terkemuka di Indonesia.',
        'Dengan pencapaian ini, Universitas Almarisah Madani tidak hanya menegaskan posisi mereka sebagai pemimpin dalam pendidikan tinggi, tetapi juga menginspirasi perguruan tinggi lainnya untuk terus berinovasi dan berkontribusi secara positif dalam dunia pendidikan. Keberhasilan ini adalah cerminan dari dedikasi seluruh civitas akademika Univeral yang terus berkomitmen untuk menciptakan dampak yang berarti dalam bidang pendidikan.',
      ],
      textEn: [
        "On August 18, 2024, Almarisah Madani University (Univeral) achieved remarkable success at the LLDIKTI Region IX Awards. The event, held to recognize exceptional accomplishments in higher education across Indonesia, awarded two significant honors to the university, underscoring Univeral’s commitment and contributions to the higher education sector.",
        '"Non-Educator Who Cares About Education" Award',
        'One of the most notable awards received by Univeral is the "Non-Educator Who Cares About Education." This award was presented to Drs. Sahibuddin A. Gani, Apt., Chairman of the Almarisah Madani Foundation, who has shown extraordinary care and dedication to advancing education in Indonesia, despite not being a direct education practitioner. His leadership and support for Almarisah Madani University and other educational initiatives have made a significant impact on the advancement of higher education in the region.',
        '"Outstanding and Innovative Leader of Private Higher Education" Award',
        'Almarisah Madani University also received the award for "Outstanding and Innovative Leader of Private Higher Education." This accolade recognizes the exemplary and innovative leadership of Dr. Nursamsiar, M.Si in managing private higher education institutions. It reflects Univeral’s success in implementing best practices, innovation, and strategies that have contributed to the university\'s progress and made a positive impact on the academic community and society at large.',
        "Univeral's Contributions and Commitment to Higher Education",
        'The success of Almarisah Madani University in earning these two prestigious awards is a testament to its strong commitment and ongoing efforts to advance higher education. Through visionary leadership, innovative management practices, and consistent support for educational initiatives, Univeral continues to play a leading role as one of Indonesia’s foremost higher education institutions.',
        'With these achievements, Almarisah Madani University not only reinforces its position as a leader in higher education but also inspires other institutions to continue innovating and contributing positively to the field of education. This success reflects the dedication of the entire academic community at Univeral, which remains committed to creating a meaningful impact in the realm of education.',
      ],
    },
  },
  {
    img: artikel12,
    titleId: "Mahasiswa PSPPA Univeral Angkatan IV Raih Hasil Tes UKMPPAI 100% Lulus",
    titleEn: "PSPPA Univeral Class IV Students Achieve 100% Passing Rate in UKMPPAI Examination",
    link: "/artikel/mahasiswa-psppa-univeral-angkatan-iv-raih-hasil-tes-ukmppai-100-lulus",
    details: {
      author: "Admin UNIVERAL",
      date: "20 Agustus 2024",
      textId: [
        "Pada tanggal 19 Agustus 2024, berita menggembirakan datang dari Program Studi Pendidikan Profesi Apoteker (PSPPA) Universitas Almarisah Madani (Univeral), dimana seluruh mahasiswa angkatan IV berhasil meraih hasil sempurna dalam Ujian Kompetensi Mahasiswa Program Profesi Apoteker Indonesia (UKMPPAI). Dengan tingkat kelulusan 100%, pencapaian ini menegaskan komitmen dan kualitas pendidikan yang diterima oleh mahasiswa PSPPA Univeral. Prestasi ini merupakan indikator kuat dari efektivitas kurikulum dan metode pengajaran yang diterapkan di PSPPA Univeral. Keberhasilan ini menandai pencapaian penting dalam menyiapkan calon apoteker yang kompeten dan siap menghadapi tantangan profesional di industri farmasi.",
        "Kesuksesan ini tidak terlepas dari persiapan intensif yang dilakukan oleh mahasiswa sepanjang masa studi mereka. Program PSPPA Univeral menawarkan kurikulum yang dirancang khusus untuk memenuhi standar kompetensi yang ditetapkan oleh UKMPPAI. Dengan dukungan pelatihan praktis yang mendalam, simulasi ujian, dan bimbingan dari para dosen berpengalaman, mahasiswa dipersiapkan dengan baik untuk menghadapi ujian kompetensi.",
        'Dukungan yang diberikan oleh dosen dan staf pengajar memainkan peran kunci dalam pencapaian ini. Pengajar di PSPPA Univeral tidak hanya memberikan pembelajaran berkualitas tinggi tetapi juga membimbing mahasiswa secara personal, memastikan mereka siap untuk ujian kompetensi. Selain itu, fasilitas modern dan sumber daya belajar yang lengkap turut berkontribusi pada kesuksesan mahasiswa.',
        'Kepala Program Studi PSPPA Univeral, Dr. apt. Budiman Yasir, S.Si menyampaikan kebanggaan dan ucapan selamat kepada seluruh mahasiswa angkatan IV. "Kami sangat bangga dengan pencapaian luar biasa ini. Hasil 100% kelulusan adalah bukti dari kerja keras mahasiswa dan dedikasi kami dalam memberikan pendidikan terbaik. Kami berkomitmen untuk terus meningkatkan kualitas program kami agar dapat terus mencetak apoteker yang berkualitas tinggi dan siap pakai," ujarnya.',
        'Hasil kelulusan 100% dari mahasiswa PSPPA Univeral angkatan IV dalam Ujian Kompetensi Mahasiswa Program Profesi Apoteker Indonesia adalah pencapaian yang sangat membanggakan dan mencerminkan kualitas tinggi dari pendidikan yang diberikan. Prestasi ini menunjukkan efektivitas kurikulum, dedikasi pengajar, dan kemampuan mahasiswa dalam mempersiapkan diri untuk memasuki dunia profesional dengan penuh percaya diri. Universitas Almarisah Madani terus berkomitmen untuk mencetak tenaga profesional apoteker yang unggul dan siap memberikan kontribusi positif dalam industri farmasi.',
      ],
      textEn: [
        "On August 19, 2024, exciting news emerged from the Program Studi Pendidikan Profesi Apoteker (PSPPA) at Universitas Almarisah Madani (Univeral), where all students from the Class IV cohort achieved a perfect score in the Ujian Kompetensi Mahasiswa Program Profesi Apoteker Indonesia (UKMPPAI). With a 100% passing rate, this accomplishment underscores the commitment and quality of education received by PSPPA students at Univeral. This achievement is a strong indicator of the effectiveness of the curriculum and teaching methods implemented at PSPPA Univeral. It marks a significant milestone in preparing competent pharmacist candidates ready to face professional challenges in the pharmacy industry.",
        'This success is a result of the intensive preparation undertaken by students throughout their studies. The PSPPA program at Univeral offers a curriculum specifically designed to meet the competency standards set by UKMPPAI. With comprehensive practical training, exam simulations, and guidance from experienced faculty members, students are well-prepared for the competency examination.',
        'The support provided by faculty and staff plays a crucial role in this achievement. The educators at PSPPA Univeral not only deliver high-quality instruction but also offer personal guidance to ensure students are prepared for the competency exam. Additionally, modern facilities and comprehensive learning resources contribute to the students\' success.',
        'Dr. Apt. Budiman Yasir, S.Si, Head of the PSPPA Program at Univeral, expressed pride and congratulations to the entire Class IV cohort. “We are extremely proud of this outstanding achievement. The 100% passing rate is a testament to the hard work of our students and our dedication to providing the best education. We are committed to continually improving the quality of our program to produce highly qualified and ready-to-work pharmacists,” he said.',
        'The 100% passing rate of PSPPA Univeral Class IV students in the UKMPPAI examination is a highly commendable achievement and reflects the high quality of education provided. This accomplishment demonstrates the effectiveness of the curriculum, the dedication of the faculty, and the students\' ability to prepare themselves confidently for the professional world. Universitas Almarisah Madani remains committed to producing excellent professional pharmacists who are ready to make positive contributions to the pharmacy industry',
      ],
    },
  },
];

export const tuitonAccordion = [
  {
    key: 1,
    title: `Fakultas Kesehatan`,
    image: tuition1,
    isOpen: false,
  },
  {
    key: 2,
    title: "Fakultas Bisnis, Teknologi dan Sosial",
    image: tuition2,
    isOpen: false,
  },
];

export const faqAccordion = [
  {
    key: 1,
    title: `Apa itu Universitas Almarisah Madani?`,
    data: `UniversitasAlmarisah Madani adalah lembaga pendidikan tinggi yang berkomitmen untuk memberikan pendidikan berkualitas tinggi dan mempersiapkan mahasiswa untuk memasuki dunia profesional.`,
    isOpen: false,
  },
  {
    key: 2,
    title: `Di mana lokasi kampus Universitas Almarisah Madani?`,
    data: `Kampus Universitas Almarisah Madani terletak di Jl. Perintis Kemerdekaan Km 13,7, Paccerakkang, Biringkanaya, Makassar Sulawesi Selatan dengan fasilitas-fasilitas modern dan aksesibilitas yang baik.`,
    isOpen: false,
  },
  {
    key: 3,
    title: `Apa program studi yang tersedia di Universitas Almarisah Madani?`,
    data: `Universitas Almarisah Madani menawarkan berbagai program studi, yaitu :`,
    list: [
      `a.	D3 Kebidanan`,
      `b.	D3 Farmasi`,
      `c.	S1 Farmasi`,
      `d.	Profesi Apoteker`,
      `e.	S1 Manajemen`,
      `f.	S1 Akuntansi`,
      `g.	S1 Ilmu Hukum`,
      `h.	S1 Ilmu Komunikasi`,
      `i.	S1 Sistem Informasi`,
      `j.	S1 Informatika. Untuk informasi`,
      `lebih lanjut, silakan kunjungi halaman www.univeral.ac.id`,
    ],
    isOpen: false,
  },
  {
    key: 4,
    title: `Bagaimana cara mendaftar di Universitas Almarisah Madani?`,
    data: `Untuk mendaftar di Universitas Almarisah Madani, Anda dapat mengunjungi halaman penerimaan di situs web resmi kami www.pmb.univeral.ic.id . Di sana Anda akan menemukan panduan langkah demi langkah tentang proses aplikasi.`,
    isOpen: false,
  },
  {
    key: 5,
    title: `Kapan tanggal-tanggal penting untuk penerimaan mahasiswa baru?`,
    data: `PMB Universitas Almarisah Madani dibuka dari tanggal 14 September 2023 sampai 14 Oktober 2023. Info lebih lanjut bisa mengunjungi web pmb www.pmb.univeral.ac.id.`,
    isOpen: false,
  },
  {
    key: 6,
    title: `Berapa biaya kuliah di Universitas?`,
    data: `Biaya kuliah di Universitas Almarisah Madani bervariasi tergantung pada program studi dan status keanggotaan Anda. Untuk informasi lebih lanjut, silakan kunjungi halaman biaya kuliah kami di www.pmb.univeral.ac.id`,
    isOpen: false,
  },
  {
    key: 7,
    title: `Apa fasilitas tambahan yang tersedia di kampus?`,
    data: `Kampus Universitas Almarisah Madani dilengkapi dengan berbagai fasilitas seperti perpustakaan, laboratorium, Klinik, Apotek, dan masih banyak lagi. Untuk rincian lengkap, silakan kunjungi halaman fasilitas kami. Selain itu Mahasiwa juga dilengkapi dengan KTM yang dapat memudahkan Mahasiswa dalam bertransaksi.`,
    isOpen: false,
  },
  {
    key: 8,
    title: `Apa kegiatan ekstrakurikuler yang tersedia untuk mahasiswa?`,
    data: `Universitas Almarisah Madani menawarkan berbagai  organisasi, dan kegiatan ekstrakurikuler. Mahasiswa dapat bergabung dengan klub yang sesuai dengan minat mereka.Ekstrakurikuler yang dimaksud seperti Badminton, Volly, Tenis, Futsa, Tari dan masih banyak lagi.`,
    isOpen: false,
  },
  {
    key: 9,
    title: `Bagaimana cara mahasiswa mengakses layanan kesehatan di kampus?`,
    data: `Layanan kesehatan tersedia di kampus Universitas Almarisah Madani melalui Klinik yang tersedia di kampus.`,
    isOpen: false,
  },
];

export const facilities = [
  {
    title: "Fasilitas",
    img: fac1,
  },
  {
    title: "Fasilitas",
    img: fac2,
  },
  {
    title: "Fasilitas",
    img: fac3,
  },
  {
    title: "Fasilitas",
    img: fac4,
  },
  {
    title: "Fasilitas",
    img: fac5,
  },
  {
    title: "Fasilitas",
    img: fac6,
  },
  {
    title: "Fasilitas",
    img: fac7,
  },
  {
    title: "Fasilitas",
    img: fac8,
  },
];
export const events = [
  {
    img: landingheader1,
    title: "EVENT NAME",
    date: "18 September 2023",
    time: "10.00 - 12.00",
  },
];

export const achievement = [
  {
    img: penghargaan1,
    title:
      "GOLD MEDAL DAN BEST POSTER INDONESIA INTERNASIONAL INVENTION EXPO 2023",
    path: "/",
  },
];

export const beasiswa = [
  {
    img: beasiswa1,
    title: "Beasiswa Jalur Undangan Prestasi",
    path: "/",
  },
  {
    img: beasiswa2,
    title: "Beasiswa Anak Pengajar",
    path: "",
  },
  {
    img: beasiswa3,
    title: "Beasiswa Basket",
    path: "",
  },
  {
    img: beasiswa4,
    title: "Beasiswa Sekolah Kerjasama",
    path: "",
  },
  {
    img: beasiswa5,
    title: "Beasiswa Alumni",
    path: "",
  },
  {
    img: beasiswa6,
    title: "Beasiswa Prestasi Khusus",
    path: "",
  },
  {
    img: beasiswa7,
    title: "Beasiswa MPK / OSIS",
    path: "",
  },
  {
    img: beasiswa8,
    title: "Beasiswa Anak Rohaniawan",
    path: "",
  },
  {
    img: beasiswa9,
    title: "Beasiswa Sosial Ekonomi",
    path: "",
  },
];

export const pmb = [
  {
    img: pmb1,
    title: "Alur Pendaftaran",
    path: "/",
  },
  {
    img: pmb2,
    title: "Beli Kode Voucher",
    path: "",
  },
  {
    img: pmb3,
    title: "Daftar Sekarang",
    path: "",
  },
  {
    img: pmb4,
    title: "Jadwal Tes Seleksi",
    path: "",
  },
  {
    img: pmb5,
    title: "Login untuk Cek Status",
    path: "",
  },
  {
    img: pmb6,
    title: "Buku Panduan PMB",
    path: "",
  },
  {
    img: pmb7,
    title: "Pindah Pilihan Program",
    path: "",
  },
  {
    img: pmb8,
    title: "Registrasi Ulang",
    path: "",
  },
  {
    img: pmb9,
    title: "Biaya Kuliah",
    path: "",
  },
  {
    img: pmb10,
    title: "Beasiswa",
    path: "",
  },
  {
    img: pmb11,
    title: "Pendaftaran bagi WNA",
    path: "",
  },
];

export const lowonganKerja = [
  {
    img: logo,
    title: "Dosen Teknik Informatika",
    data: "Penempatan di Makassar",
  },
  {
    img: logo,
    title: "Dosen Teknik Informatika",
    data: "Penempatan di Makassar",
  },
  {
    img: logo,
    title: "Dosen Teknik Informatika",
    data: "Penempatan di Makassar",
  },
];

// Leturers Profile
export const lecturersProfile = [
  {
    id: 1,
    photo: rektorProfile,
    name: "DR. Nursamsiar. M.Si.",
    position: "Rektor Universitas Almarisah Madani",
    list: 1,
  },
  {
    id: 2,
    photo: wr1,
    name: "apt. Zulham, S.Farm, M.Si",
    position: "Wakil Rektor I Bidang Akademik dan Kemahasiswaan",
    list: 1,
  },
  {
    id: 200,
    photo: lecturer,
    name: "Asty Almaida, SE.,M.Si",
    position: "Wakil Rektor II Bidang Sumber Daya Manusia dan Sarana Prasarana",
  },
  {
    id: 2,
    photo: wr3,
    name: "apt. Besse Hardianti, S.Si, M.Pharm., Sc., Ph.D",
    position: "Wakil Rektor III Bidang Riset, Inovasi dan Pengembangan",
    list: 2,
  },
  {
    id: 3,
    photo: dekanfk,
    name: "Dr. apt. Fajriansyah, S.Farm., M.Si",
    position: "Dekan Fakultas Kesehatan",
    list: 1,
  },
  {
    id: 3,
    photo: dekanfbts,
    name: "Alamsyah Yasir, SE., M.M",
    position: "Dekan Fakultas Bisnis, Teknologi dan Sosial",
    list: 2,
  },
  {
    id: 4,
    photo: lembagaPPM,
    name: "Dr. apt. Nur Khairi, S.Si., M.Si",
    position:
      "Ketua Lembaga Penelitian dan Pengabdian Masyarakat (LPPM) Universitas Almarisah Madani",
    list: 1,
  },
  {
    id: 4,
    photo: lembagaPMI,
    name: "Dr. apt. Maulita Indrisari, S.Si., M.Si",
    position:
      "Ketua Lembaga Penjaminan Mutu (LPMI) Universitas Almarisah Madani",
      list: 2,
  },
  {
    id: 5,
    photo: kaprodiPSPPA,
    name: "Dr. apt. Budiman Yasir, S.Si",
    position: "Ka Prodi Profesi Apoteker",
    list: 1,
  },
  {
    id: 5,
    photo: kaprodiS1Farmasi,
    name: "apt. Nurul Arfiyanti Yusuf, S.Farm., M.Si",
    position: "Ka Prodi S1 Farmasi",
    list: 2,
  },
  {
    id: 5,
    photo: kaprodiD3Farmasi,
    name: "Dr. apt. Abd. Halim Umar, S.Farm., M.Si",
    position: "Ka Prodi D3 Farmasi",
    list: 3,
  },
  {
    id: 5,
    photo: kaprodiD3Kebidanan,
    name: "Sukmawati, SST., M.Kes",
    position: "Ka Prodi D3 Kebidanan",
    list: 4,
  },
  {
    id: 5,
    photo: kaprodiManajemen,
    name: "Abdul Razak J. Sabara, S.E., M.M",
    position: "Ka Prodi Manajemen",
    list: 5,
  },
  {
    id: 5,
    photo: kaprodiAkuntansi,
    name: "Rahadian Cahyadi, S.E., M.Ak.",
    position: "Ka Prodi Akuntansi",
    list: 6,
  },
  {
    id: 5,
    photo: kaprodiSisfor,
    name: "Andi Ikmal Rachman, S.Kom., M.M",
    position: "Ka Prodi Sistem Informasi",
    list: 7,
  },
  {
    id: 5,
    photo: kaprodiInfor,
    name: "Siska Armalivia, S.Kom., M.T",
    position: "Ka Prodi Informatika",
    list: 8,
  },
  {
    id: 5,
    photo: kaprodiIlkom,
    name: "Muhammad Taslim, S.I.Kom., M.I.Kom",
    position: "Ka Prodi Ilmu Komunikasi",
    list: 9,
  },
  {
    id: 5,
    photo: kaprodiIlmuHukum,
    name: "Syawal Amirul Syah, S.H.,M.H",
    position: "Ka Prodi Hukum",
    list: 10,
  },
  {
    id: 6,
    photo: ketuaGugusFK,
    name: "apt. Andi Ardiansyah Arifin, S.Si., M.Sc",
    position: "Ketua Gugus Penjaminan Mutu Fakultas Ilmu Kesehatan",
    list: 1,
  },
  {
    id: 6,
    photo: ketuaGugusFBTS,
    name: "Andi Adam Maulana Yusvan, S.E., M.S.M",
    position:
      "Ketua Gugus Penjaminan Mutu Fakultas Bisnis, Teknologi, dan Sosial",
      list: 2,
  },
  {
    id: 7,
    photo: kuppmFK,
    name: "apt. Lukman, S.Si., M.Farm",
    position:
      "Ketua Unit Penelitian dan Pengabdian Masyarakat Fakultas Ilmu Kesehatan",
    list: 1,
  },
  {
    id: 7,
    photo: kuppmFBTS,
    name: "Thiara Tri Funny Manguma S.Kom., M.T",
    position:
      "Ketua Unit Penelitian dan Pengabdian Masyarakat Fakultas Bisnis, Teknologi, dan Sosial",
      list: 2,
  },
];

export const LecProfileTableData = [
  {
    name: "Dr. apt. Wahyu Hendrarti, S.SI., M.Kes.",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Dr. Nursamsiar, M.Si",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Dr. apt. Maulita Indrisari, S.Si., M.Si.",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "apt. Besse Hardianti, S.Si., M.Pharm., Sc.",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Dr. apt. Nur Khairi, S.Si., M.Si.",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Reny Syahruni, S.Farm., M.Sc",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Michrun Nisa, S.Farm., M.Sc",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Dr. Abd. Halim Umar, S.Farm., M.Si",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Syamsu Nur, S.Farm., M.Sc., Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Dr. Fajriansyah, S.Farm., M.Si., Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "apt. Radhia Riski, S.Si., M.Si.",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Dr. Fitriyanti Jumaetri Sami, S.Si., M.Si",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "apt. Nurul Arfiyanti Yusuf, S.Farm., M.Si.",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Astuti, S.Si., M.Si",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "apt. Maria Ulfa, S.Farm., M.Si.",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Sitti Rahimah, S.Farm., M.Si., Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Sukriani Kursia, S.Farm., M.Kes",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Mirnawati Salampe, S.Si., Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Rahmad Aksa, S.Si., M.Si., Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Saldi Hapiwaty, S.Farm., M.Kes. Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Hamdayani L.A., S.Si., M.SI., Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Akbar Awaluddin, S.Si., M.Si., Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Yuri Pratiwi Utami, S.Farm., M.Si., Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Lukman, S.Si., Apt., M.Farm",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Asril Burhan, S.Farm., M.Si., Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Khairuddin, S.Si., M.Si., Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Megawati, S.Pd., M.Si",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Andi Nur Aisyah, S.Si., M.Si., Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Zulham, S.Farm., M.Si., Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Fadillah Maryam B.A., S.Farm., M.Si., Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Ismail, S.Farm., M.Sc",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Imrawati, S.Si., M.Si., Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Fhahri Mubarak, S.Farm., M.Si",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Suwahyuni Mus, S.Si., M.Kes., Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Marwati, S.Farm., M.Si.",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "apt. Amriani Sapra, S.Farm., M.Si",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "apt. Andi Paluseri, SE,  S.Farm, M.Kes",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "apt. Noer Fauziah Rahman, S.Farm, M.S.Farm",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Prof. Dr. M. Natsir Djide, MS, Apt",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "apt. Tuti Handayani Zainal, S.Farm, M.Si",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "apt. Nurzadrina Wahyuddin, S.Farm, M.Si",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "apt. Muh. Azwar AR, S.Si., M.Si",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "apt. Dewi Purwaningsih, S.Si, M.S.Farm",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Nurul Hikma, S.Farm, M.Si",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "apt. Nur Rahma Rumata, S.Farm, M.Si",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Rukman Muslimin",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Dr. apt. Budiman Yasir., S.Si",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Burhanuddin",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Muh. Syahrul",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "apt. Andi Ardiansyah Arifin, S.Si, M.Sc",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "Ikbal, S.Farm., M.Si",
    studyProg: "Farmasi",
    faculty: "fk",
  },
  {
    name: "dr. Sujud Zainur Rasyid, S.Ked, M.Si",
    studyProg: "Farmasi",
    faculty: "fk",
  },

  // AKBID
  {
    name: "Ary Handayani, SST, M. Kes",
    studyProg: "Kebidanan",
    faculty: "fk",
  },
  {
    name: "Sukmawati, SST, M. Kes",
    studyProg: "Kebidanan",
    faculty: "fk",
  },
  {
    name: "Husnul Hatima, SST., M. Kes",
    studyProg: "Kebidanan",
    faculty: "fk",
  },
  {
    name: "Nirwana, SST., M. Keb",
    studyProg: "Kebidanan",
    faculty: "fk",
  },
  {
    name: "Nurul Fitrahminarsih, SST., M. Keb",
    studyProg: "Kebidanan",
    faculty: "fk",
  },
  {
    name: "Endang Sulistyowati, SKM, M. Kes",
    studyProg: "Kebidanan",
    faculty: "fk",
  },
  {
    name: "Hafsah, ST., M. Kes",
    studyProg: "Kebidanan",
    faculty: "fk",
  },

  // FBTS

  // Akuntansi
  {
    name: "Nur Asmi Ainun Kamal, S.E., M.Ak",
    studyProg: "Akuntansi",
    faculty: "fbts",
  },
  {
    name: "Rahadian Cahyadi, S.E., M.Ak.",
    studyProg: "Akuntansi",
    faculty: "fbts",
  },
  {
    name: "Fia Fauzia Burhanuddin, B.Bus., M.Ak",
    studyProg: "Akuntansi",
    faculty: "fbts",
  },
  {
    name: "Melsa Jumliana, S.E.,M.Ak",
    studyProg: "Akuntansi",
    faculty: "fbts",
  },
  {
    name: "Fika Hartina Sari, A. Md, S.E.,M.Ak",
    studyProg: "Akuntansi",
    faculty: "fbts",
  },
  {
    name: "Sitti Jam'iah H.A, S.E., M.Ak",
    studyProg: "Akuntansi",
    faculty: "fbts",
  },

  // Manajemen
  {
    name: "Alamsyah Yasir, S.E., M.M",
    studyProg: "Manajemen",
    faculty: "fbts",
  },
  {
    name: "Abdul Razak J. Sabara, S.E., M.M",
    studyProg: "Manajemen",
    faculty: "fbts",
  },
  {
    name: "Andi Adam Maulana Yusvan, S.E., M.S.M",
    studyProg: "Manajemen",
    faculty: "fbts",
  },
  {
    name: "Basso Yusril, S.E., M.M",
    studyProg: "Manajemen",
    faculty: "fbts",
  },
  {
    name: "Jessie Alisa Rano S.M., M.M",
    studyProg: "Manajemen",
    faculty: "fbts",
  },

  // Hukum
  {
    name: "Sri Hasrina, S.H.,M.H",
    studyProg: "Hukum",
    faculty: "fbts",
  },
  {
    name: "Asrullah Dimas, S.H., M.H",
    studyProg: "Hukum",
    faculty: "fbts",
  },
  {
    name: "Inda Sari Palinrungi, S.H.,M.H",
    studyProg: "Hukum",
    faculty: "fbts",
  },
  {
    name: "Mirnayanti, S.H.,M.H",
    studyProg: "Hukum",
    faculty: "fbts",
  },
  {
    name: "Syawal Amirul Syah, S.H.,M.H",
    studyProg: "Hukum",
    faculty: "fbts",
  },

  // Sistem Informasi
  {
    name: "Andi Ikmal Rachman, S.Kom., M.M",
    studyProg: "Sistem Informasi",
    faculty: "fbts",
  },
  {
    name: "M. Rudini Kurniawan Amiruddin, S.ST., M.T",
    studyProg: "Sistem Informasi",
    faculty: "fbts",
  },

  // Ilmu Komunikasi
  {
    name: "Dea Audia Elsaid, S.I.Kom., M.I.Kom",
    studyProg: "Ilmu Komunikasi",
    faculty: "fbts",
  },
  {
    name: "Nur Istiqamah Desiana, S.Sos., M.I.Kom",
    studyProg: "Ilmu Komunikasi",
    faculty: "fbts",
  },
  {
    name: "Emil Fatra, S.I.Kom., M.I.Kom",
    studyProg: "Ilmu Komunikasi",
    faculty: "fbts",
  },
  {
    name: "Muhammad Taslim, S.I.Kom., M.I.Kom",
    studyProg: "Ilmu Komunikasi",
    faculty: "fbts",
  },
  {
    name: "Muh Nurqadri Jamal, S.Sos., M.I.Kom",
    studyProg: "Ilmu Komunikasi",
    faculty: "fbts",
  },
  {
    name: "Suherli, S.I.Kom., M.I.Kom",
    studyProg: "Ilmu Komunikasi",
    faculty: "fbts",
  },

  // Informatika
  {
    name: "Siska Armalivia, S.Kom., M.T",
    studyProg: "Informatika",
    faculty: "fbts",
  },
  {
    name: "Thiara Tri Funny Manguma S.Kom., M.T",
    studyProg: "Informatika",
    faculty: "fbts",
  },
  {
    name: "Muhammad Abdillah Rahmat, S.T., M.T",
    studyProg: "Informatika",
    faculty: "fbts",
  },
];

export const LecProfileTableCol = [
  {
    Header: "ID",
    accessor: "id",
    width: 6,
  },
  {
    Header: "Nama",
    accessor: "name",
  },
  {
    Header: "Prodi",
    accessor: "studyProg",
  },
];
export const textArtikel = [
  {
    id: 1,
    author: "admin",
    date: "18 September 2023",
    img: artikel1p1,
    title:
      "Mahasiswa Universitas Almarisah Madani Meraih Gold Medal pada Ajang Indonesia International Invention",
    text: [
      `Sekolah Tinggi Ilmu Farmasi Makassar resmi berubah bentuk menjadi Universitas Almarisah Madani pada tanggal 28 Agustus 2023 ditandai dengan keluarnya Surat Keputusan Menteri Pendidikan, Kebudayaan, Riset, dan Teknologi Republik Indonesia tentang Izin penggabungan Sekolah Tinggi Ilmu Farmasi Makassar di Kota Makassar dan Akademi Kebidanan Minasa Upa di Kota Makassar menjadi Universitas Almarisah Madani di Kota Makassar Provinsi Sulawesi Selatan yang diselenggarakan oleh Yayasan Almarisah Madani. `,
      `Surat keputusan Perubahan Bentuk diserahkan langsung oleh Ketua LLDIKTI Wilayah IX, Drs.Andi Lukman kepada Ketua Yayasan Almarisah Madani, Drs. H. Sahibuddin A. Gani, apt. selaku Badan Penyelenggara pada tanggal 08 September 2023. Pada tanggal yang sama, Rektor pertama Universitas Almarisah Madani, Dr. Nursamsiar, M.Si dilantik untuk periode 2023-2028.
      `,
      `Dalam pidatonya, Rektor Universitas Almarisah Madani menyebutkan bahwa Universitas Almarisah  Madani  akan memberikan pendidikan yang relevan, inovatif, dan berorientasi pada nilai, serta memfasilitasi penelitian yang mendorong perkembangan ilmu pengetahuan dan masyarakat. Kami ingin berkolaborasi dengan komunitas lokal dan global untuk mengatasi tantangan-tantangan global dan mewujudkan visi kami bersama. 
      `,
    ],
  },
];

export const languages = {
  id: { icon: idFlag, nativeName: "Bahasa" },
  en: { icon: usFlag, nativeName: "English" },
};
